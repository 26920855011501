import {Component, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {MsgService} from 'src/app/services/msg-service.service';
import {environment} from 'src/environments/environment';
import {Stage} from 'src/utils/types';

@Component({
    selector: 'app-challenge',
    templateUrl: './challenge.component.html',
    styleUrls: ['./challenge.component.css', '../auth.styles.css'],
    providers: [],
    encapsulation: ViewEncapsulation.None,
})
export class ChallengeComponent {
    constructor(
        private authService: AuthService,
        private router: Router,
        private messageService: MsgService
    ) {}
    passwordStrongRegex =
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
    ongoingRequest = false;

    challengeForm = new FormGroup({
        password: new FormControl('', {validators: Validators.required}),
        confirmPassword: new FormControl('', {
            validators: [
                Validators.required,
                Validators.pattern(this.passwordStrongRegex),
            ],
        }),
    });

    htmlArguments = {
        header: 'Neues Passwort setzen',
        input1: 'Neues Passwort',
        input2: 'Passwort wiederholen',
    };

    isSandbox() {
        return environment.stage === Stage.sandbox;
    }

    setPassword() {
        this.ongoingRequest = true;
        this.authService
            .setNewRequiredPassword(
                this.challengeForm.controls.password.value ?? '' //Abfrage ob beide gleich
            )
            .then(() => {
                this.ongoingRequest = false;
                this.router.navigateByUrl('/');
            })
            .catch(e => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Fehler beim Ändern des Passworts',
                    detail: (e as Error).message, // auslagern
                });
                this.challengeForm.reset();
                this.ongoingRequest = false;
            });
    }

    //Validates that the password has at least one lowercase letter, one uppercase letter, one number, one special character and a minimum length of 8 characters.
    hasLowerCase(): boolean {
        const controlValue = this.challengeForm.get('password')?.value ?? '';
        const regex = /[a-z]/;
        return regex.test(controlValue);
    }

    hasUpperCase(): boolean {
        const controlValue = this.challengeForm.get('password')?.value ?? '';
        const regex = /[A-Z]/;
        return regex.test(controlValue);
    }

    hasNumber(): boolean {
        const controlValue = this.challengeForm.get('password')?.value ?? '';
        const regex = /\d/;
        return regex.test(controlValue);
    }

    hasMinLength(): boolean {
        const controlValue = this.challengeForm.get('password')?.value ?? '';
        return controlValue.length >= 8;
    }
    hasSpecialCharacter(): boolean {
        const controlValue = this.challengeForm.get('password')?.value ?? '';
        const regex = /[!@#$%^&*(),.?":{}|<>]/;
        return regex.test(controlValue);
    }

    equalPassword(): boolean {
        return (
            this.challengeForm.get('password')?.value ===
            this.challengeForm.get('confirmPassword')?.value
        );
    }
    checkPolicy() {
        const response = this.challengeForm.controls.password.value?.match(
            this.passwordStrongRegex
        );
        const box1 = this.challengeForm.controls.password.value ?? '';

        return box1.length > 0 ? response : true;
    }
}
