import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DialogService} from '@timebox/custom-primeng/dynamicdialog';
import {TimesetService} from 'src/app/services/time-api-timeset.service';
import {Timeset, LightTimeset} from 'src/app/models/api-models/Timeset';
import {ConfirmationService} from '@timebox/custom-primeng/api';
import {MsgService} from 'src/app/services/msg-service.service';
import {DatePipe, Location} from '@angular/common';
import {colors} from 'src/utils/tools';
import {UpdateTimesetRequest} from 'src/app/models/api-models/UpdateTimesetRequest';
import {TimesetEvent} from 'src/app/models/api-models/Event';
import {toDate, toTimestamp} from 'src/utils/converter';
import {TimesetEventType} from 'src/app/models/EventType';
import {ActivatedRoute, Router} from '@angular/router';
import {SUCCESS_MESSAGES} from 'src/app/services/toast-messages';
import {ThemesService} from 'src/app/services/themes.service';

@Component({
    selector: 'app-timeset-overview',
    templateUrl: './timeset-overview.component.html',
    styleUrls: ['./timeset-overview.component.css'],
    providers: [DialogService, ConfirmationService, DatePipe],
    encapsulation: ViewEncapsulation.None,
})
export class TimesetOverviewComponent implements OnInit, OnDestroy {
    monthTimesets: LightTimeset[] = [];
    dateTimesets: LightTimeset[] = [];
    selectedTimeset: Timeset | undefined;
    selectedTimesetIndex: number = -1;
    loadingMonth = true;
    requestForDateIndex = -1;
    loadingSelection = false;
    currentDate: Date | undefined;
    editMode: boolean = false;
    parsedDate: Date | undefined;
    parsedTimesetId: string | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentQueryParams: {[key: string]: any} = {};
    hasChanged: boolean = false;
    maxDate = new Date();
    requestMonth: boolean = false;

    constructor(
        private timesetApi: TimesetService,
        private config: ThemesService,
        private confirmationService: ConfirmationService,
        private msgService: MsgService,
        private datePipe: DatePipe,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.datePipe = new DatePipe('de-DE');
        window.onbeforeunload = (e: BeforeUnloadEvent) => {
            if (this.editMode && this.hasChanged) {
                e.preventDefault();
                e.returnValue =
                    'Du befindest dich gerade in der Bearbeitung. Soll das Fenster trotzdem geschlossen werden? Änderung gehen dabei verloren.';
                return;
            }
            delete e['returnValue'];
        };
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.currentQueryParams = {...params};

            if (params['tid']) {
                this.parsedTimesetId = params['tid'];
            }

            const dateString = params['date'];
            if (dateString) {
                this.parsedDate = new Date(parseInt(dateString));
                this.changeMonth({
                    month: this.parsedDate.getMonth() + 1,
                    year: this.parsedDate.getFullYear(),
                });
            } else {
                const dt = new Date();
                this.changeMonth({
                    month: dt.getMonth() + 1,
                    year: dt.getFullYear(),
                });
            }
        });
    }

    ngOnDestroy() {
        window.onbeforeunload = () => {};
    }

    changeMonth(event: {month?: number; year?: number}) {
        if (!event.month || !event.year) {
            return;
        }
        this.loadingMonth = true;
        this.currentDate = new Date(event.year!, event.month!, 0);
        this.timesetApi
            .getLightTimesetsForMonth(event.month - 1, event.year)
            .subscribe({
                next: t => {
                    this.monthTimesets = t;
                    this.selectedTimeset = undefined;
                    this.currentDate = new Date(event.year!, event.month!, 0);
                    this.selectedTimesetIndex = -1;
                    const today = new Date();

                    if (
                        this.parsedDate &&
                        this.isSameMonthAndYear(
                            this.currentDate,
                            this.parsedDate
                        )
                    ) {
                        //Checking if we parsed a date from queryparam and setting it as the current date
                        this.currentDate.setDate(this.parsedDate.getDate());
                    } else if (
                        this.isSameMonthAndYear(this.currentDate, today)
                    ) {
                        //Checking if we are in the current month (and year)
                        this.currentDate.setDate(
                            today.getDate() > 1 ? today.getDate() - 1 : 1
                        );
                    }

                    this.changeSelectedDate();
                    this.loadingMonth = false;
                },
                error: err => {
                    this.msgService.handleError(err, this.constructor.name);
                },
            });
    }

    isEditable(timeset?: Timeset | LightTimeset) {
        const startOfToday = new Date();
        startOfToday.setUTCHours(23, 59, 59, 999);

        const lastPossibleEditTime =
            toTimestamp(startOfToday) -
            this.config.config.recordableDaysInPast * 24 * 3600;

        if (timeset) {
            return timeset.start > lastPossibleEditTime;
        }

        if (this.currentDate) {
            return toTimestamp(this.currentDate) > lastPossibleEditTime;
        }
        return false;
    }

    isSameMonthAndYear(date1: Date, date2: Date) {
        return (
            date1.toLocaleDateString('de-DE', {
                month: '2-digit',
                year: 'numeric',
            }) ===
            date2.toLocaleDateString('de-DE', {
                month: '2-digit',
                year: 'numeric',
            })
        );
    }

    changeSelectedDate() {
        if (this.currentDate) {
            this.updateQueryParams('date', this.currentDate.getTime());

            const startDate = new Date(this.currentDate.setHours(0, 0, 0, 0));
            const startDateTimeStamp = Math.floor(startDate.getTime() / 1000);
            const endDate = new Date(
                this.currentDate.setHours(23, 59, 59, 999)
            );
            const endDateTimeStamp = Math.floor(endDate.getTime() / 1000);

            this.dateTimesets = this.monthTimesets.filter(
                t => t.start >= startDateTimeStamp && t.start < endDateTimeStamp
            );
            if (this.dateTimesets.length > 0) {
                this.updateQueryParams('tid', this.dateTimesets[0].timesetId);

                const index = this.dateTimesets.findIndex(
                    t => t.timesetId === this.parsedTimesetId
                );
                if (index > -1) {
                    this.loadTimeset(this.parsedTimesetId!);
                    this.selectedTimesetIndex = index;
                } else {
                    this.loadTimeset(this.dateTimesets[0].timesetId);
                    this.selectedTimesetIndex = 0;
                }
            } else {
                this.selectedTimeset = undefined;
                this.selectedTimesetIndex = -1;
                this.updateQueryParams('tid', undefined);
            }
        }
    }

    loadTimeset(timesetId: string) {
        this.loadingSelection = true;
        this.timesetApi.getTimesetById(timesetId).subscribe({
            next: t => {
                this.selectedTimeset = t;
                this.rerenderDateTimesets();
                this.loadingSelection = false;
            },
            error: err => {
                this.msgService.handleError(err, this.constructor.name);
            },
        });
    }

    getTimesetString(timeset: Timeset | LightTimeset): string {
        if (!timeset) {
            return 'Keine Daten vorhanden';
        }
        const startTime = this.datePipe.transform(
            timeset.start * 1000,
            'HH:mm'
        );
        const endTime = this.datePipe.transform(timeset.end! * 1000, 'HH:mm');
        return `${startTime} - ${endTime}`;
    }

    selectTimeset(index: number, source?: string) {
        if (index === this.selectedTimesetIndex) return;
        const timeset = this.dateTimesets.at(index);
        if (timeset) {
            this.loadTimeset(timeset.timesetId);
            this.selectedTimesetIndex = index;

            if (source === 'html') {
                this.updateQueryParams('tid', timeset.timesetId);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateQueryParams(key: string, value: any) {
        if (value === undefined) {
            delete this.currentQueryParams[key];
        } else {
            this.currentQueryParams[key] = value;
        }
        const url = this.router
            .createUrlTree([], {
                relativeTo: this.route,
                queryParams: this.currentQueryParams,
            })
            .toString();

        this.location.go(url);
    }

    editTimeset(timesetId: string) {
        if (timesetId) {
            this.editMode = true;
            this.hasChanged = false;
        }
    }

    addTimeset() {
        if (!this.currentDate) return;
        const latestExisting = this.dateTimesets.at(-1)?.end;
        const start = latestExisting
            ? toDate(latestExisting + 60)
            : new Date(
                  this.currentDate.getFullYear(),
                  this.currentDate.getMonth(),
                  this.currentDate.getDate(),
                  8,
                  30
              );
        const end = latestExisting
            ? toDate(latestExisting + 60 + 120 * 60)
            : new Date(
                  this.currentDate.getFullYear(),
                  this.currentDate.getMonth(),
                  this.currentDate.getDate(),
                  17,
                  0
              );
        const creationTimeset = {
            timesetId: '',
            start: toTimestamp(start),
            end: toTimestamp(end),
            sealed: false,
            events: [
                {
                    type: TimesetEventType.WORK,
                    start: toTimestamp(start),
                    end: toTimestamp(end),
                },
            ],
        };

        if (!latestExisting) {
            creationTimeset.events = [
                {
                    type: TimesetEventType.WORK,
                    start: toTimestamp(start),
                    end: toTimestamp(start) + 4 * 60 * 60,
                },
                {
                    type: TimesetEventType.BREAK,
                    start: toTimestamp(start) + 4 * 60 * 60,
                    end: toTimestamp(start) + 4.5 * 60 * 60,
                },
                {
                    type: TimesetEventType.WORK,
                    start: toTimestamp(start) + 4.5 * 60 * 60,
                    end: toTimestamp(end),
                },
            ];
        }

        this.dateTimesets.push({...creationTimeset});
        this.selectedTimeset = creationTimeset;
        this.selectedTimesetIndex = this.dateTimesets.length - 1;
        this.editMode = true;
        this.hasChanged = true;
    }
    //TODO: UXModel erweitern, im Backend und Frontend um zu validieren ob der Nutzer manuelle exports durchführen kann.
    //TODO: Im UXModel prüfen, ob der user das darf, wenn es erlaubt ist den Button anzeigen(Über variable im html)
    sendMonthlyReport() {
        if (!this.currentDate) return;
        this.timesetApi
            .requestReporting(
                this.currentDate?.getFullYear(),
                this.currentDate?.getMonth() + 1
            )
            .subscribe({
                next: () => {
                    this.msgService.add(SUCCESS_MESSAGES['REPORT_REQUESTED']);
                    this.requestMonth = true;
                    setTimeout(() => {
                        this.requestMonth = false;
                    }, 60000);
                },
                error: err => {
                    this.msgService.handleError(err, this.constructor.name);
                    this.requestMonth = true;
                    setTimeout(() => {
                        this.requestMonth = false;
                    }, 60000);
                },
            });
    }

    rerenderDateTimesets() {
        if (!this.selectedTimeset) return;

        const dateIndex = this.dateTimesets.findIndex(
            t => t.timesetId === this.selectedTimeset?.timesetId
        );
        const monthIndex = this.monthTimesets.findIndex(
            t => t.timesetId === this.selectedTimeset?.timesetId
        );

        const newItem: LightTimeset = {
            timesetId: this.selectedTimeset.timesetId,
            start: this.selectedTimeset.events[0].start,
            end: this.selectedTimeset.events.at(-1)?.end,
        };

        if (dateIndex >= 0) {
            this.dateTimesets.splice(dateIndex, 1, newItem);
        }

        if (monthIndex >= 0) {
            this.monthTimesets.splice(monthIndex, 1, newItem);
        }

        this.hasChanged = true;
    }

    handleCancelTrigger(event: Event) {
        if (this.hasChanged) {
            this.confirmationService.confirm({
                target: event.target ?? undefined,
                message: 'Wirklich alle Änderungen verwerfen?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //confirm action
                    this.cancelEditing();
                },
                reject: () => {
                    //reject action
                },
            });
        } else {
            this.cancelEditing();
        }
    }

    private cancelEditing() {
        if (this.selectedTimeset?.timesetId === '') {
            this.dateTimesets.pop();
        }
        this.changeSelectedDate();
        this.editMode = false;
    }

    requestTimesetDeletion(timeset: Timeset | LightTimeset, event: Event) {
        this.confirmationService.confirm({
            target: event.target ?? undefined,
            message: 'Soll diese Erfassung wirklich gelöscht werden?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //confirm action
                this.deleteTimeset(timeset.timesetId);
            },
            reject: () => {
                //reject action
            },
        });
    }

    private deleteTimeset(timesetId: string) {
        this.loadingSelection = true;
        this.timesetApi.deleteTimeset(timesetId).subscribe({
            next: () => {
                const dateIndex = this.dateTimesets.findIndex(
                    t => t.timesetId === timesetId
                );
                if (dateIndex >= 0) {
                    this.dateTimesets.splice(dateIndex, 1);
                } else {
                    console.error(
                        `timeset ${timesetId} already removed in dateList.`
                    );
                }
                const monthIndex = this.monthTimesets.findIndex(
                    t => t.timesetId === timesetId
                );
                if (monthIndex >= 0) {
                    this.monthTimesets.splice(monthIndex, 1);
                } else {
                    console.error(
                        `timeset ${timesetId} already removed in monthList.`
                    );
                }
                this.editMode = false;
                this.loadingSelection = false;
                this.changeSelectedDate();
                this.msgService.add(SUCCESS_MESSAGES['DELETE_TIMESET']);
            },
        });
    }

    handleSaveTrigger() {
        const timeset = this.selectedTimeset!;
        const utr: UpdateTimesetRequest = {
            events: timeset.events as TimesetEvent[],
        };
        this.loadingSelection = true;
        if (timeset.timesetId !== '') {
            this.timesetApi.updateTimeset(utr, timeset.timesetId).subscribe({
                next: () => {
                    this.editMode = false;
                    this.loadingSelection = false;
                    this.dateTimesets.map(t => {
                        if (t.timesetId === timeset.timesetId) {
                            return {
                                ...t,
                                timesetId: timeset.timesetId,
                                start: timeset.start,
                                end: timeset.end,
                            };
                        }
                        return t;
                    });
                    this.msgService.add(SUCCESS_MESSAGES['SAVED_TIMESET']);
                },
                error: err => {
                    this.msgService.handleError(err, this.constructor.name);
                    this.editMode = true;
                    this.loadingSelection = false;
                },
            });
        } else {
            this.timesetApi.createTimeset(utr).subscribe({
                next: createdTimeset => {
                    this.editMode = false;
                    this.loadingSelection = false;
                    this.monthTimesets.push(createdTimeset);
                    this.changeSelectedDate();
                    this.msgService.add(SUCCESS_MESSAGES['SAVED_TIMESET']);
                },
                error: err => {
                    this.msgService.handleError(err, this.constructor.name);
                    this.editMode = true;
                    this.loadingSelection = false;
                },
            });
        }
    }

    getDateStyle(date: {day: number; month: number; year: number}) {
        const dateObj = new Date(date.year, date.month, date.day);
        const unix = Math.trunc(dateObj.getTime() / 1000);
        const style = {
            padding: '1rem',
            color: 'var(--text-color)',
            'background-color': 'unset',
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let calenderDate: any;
        let currentColor: string = 'transparent';
        if (
            this.monthTimesets?.some(t => {
                calenderDate = t;
                return t.start >= unix && t.start < unix + 60 * 60 * 24;
            })
        ) {
            const start = calenderDate.start;
            const end = calenderDate.end!;

            if ((end - start) / 3600 > 11) {
                currentColor = colors.errorColor;
                style['background-color'] = colors.errorColor;
            } else if ((end - start) / 3600 <= 11) {
                currentColor = colors.workColor;
                style['background-color'] = colors.workColor;
            }

            style.color = 'var(--text-color-white)';
        }
        if (
            date.day === this.currentDate?.getDate() &&
            date.month === this.currentDate.getMonth() &&
            date.year === this.currentDate.getFullYear()
        ) {
            style['background-color'] = currentColor;
            currentColor === colors.errorColor ||
            currentColor === colors.workColor
                ? (style.color = 'var(--text-color-white)')
                : (style.color = 'var(--text-color)');
        }
        return style;
    }
    getIsManualExportAllowed() {
        return this.config.config.isManualExportAllowed;
    }
}
