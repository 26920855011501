import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MenuItem, ConfirmationService} from '@timebox/custom-primeng/api';
import {TimesetEventType, StampEventType} from 'src/app/models/EventType';
import {Timeset} from 'src/app/models/api-models/Timeset';
import {StampEvent, TimesetEvent} from 'src/app/models/api-models/Event';
import {EventView} from 'src/app/models/views/EventView';
import {EventService} from 'src/app/services/time-api-event.service';
import {buildEventView, getEnumFromString, roundMinutes} from 'src/utils/tools';
import {TimesetService} from 'src/app/services/time-api-timeset.service';
import {MsgService} from 'src/app/services/msg-service.service';
import {toDate, toTimestamp} from 'src/utils/converter';
import {Comment} from 'src/app/models/views/CommentView';
import {ThemesService} from 'src/app/services/themes.service';
import {SUCCESS_MESSAGES} from 'src/app/services/toast-messages';

@Component({
    selector: 'app-timeset-screen',
    templateUrl: './timeset-screen.component.html',
    styleUrls: ['./timeset-screen.component.css'],
    providers: [ConfirmationService],
})
export class TimesetScreenComponent implements OnInit {
    eventViews: EventView[] = [];
    currentTimeset: Timeset | undefined;
    lastCheckoutReminder: number = 0;
    userInput: string = '';

    dialogState:
        | undefined
        | {
              commentControl: FormControl | undefined;
              event: TimesetEvent;
              eventIndex: number;
              buttonsEnabled: boolean;
          };

    // vars vro showing validation errors
    validationErrors: string[] = [];
    comments: Comment[] = [];

    ongoingRequest = true;
    defaultEventType = 'Arbeit Beginn';
    eventTypes: MenuItem[] = [
        {
            label: 'Pause Beginnen',
            command: () => this.uploadTimeEvent('START_BREAK'),
        },
        {
            label: 'Arbeit Beenden',
            command: () => this.prepareStopWork(),
        },
    ];

    eventMap: {[key: string]: string} = {
        'Arbeit Beginnen': 'START_WORK',
        'Pause Beginnen': 'START_BREAK',
        'Arbeit Beenden': 'STOP_WORK',
    };

    timeControl = new FormControl(new Date());
    manualTime = false;
    private autoTimeInterval: number | undefined;

    constructor(
        private timesetService: TimesetService,
        private eventService: EventService,
        private messageService: MsgService,
        private confirmationService: ConfirmationService,
        public themeService: ThemesService
    ) {}

    ngOnInit() {
        this.loadToday();
        this.startAutoTime();
        this.timeControl.valueChanges.subscribe({
            next: val => {
                this.validateTimestamp(val);
                this.manualTime = true;
                clearInterval(this.autoTimeInterval);
            },
        });
    }

    todayForm: FormGroup = new FormGroup({
        timeControl: this.timeControl,
    });

    startAutoTime() {
        this.manualTime = false;
        this.timeControl.setValue(roundMinutes(), {emitEvent: false});
        this.validateTimestamp(this.timeControl.value);

        this.autoTimeInterval = window.setInterval(() => {
            if (!this.manualTime && !this.ongoingRequest) {
                this.timeControl.setValue(roundMinutes(), {emitEvent: false});
                // Updating today View but not comments
                this.updateToday(this.currentTimeset, false);
            }
        }, 2500);
    }

    uploadTimeEvent(type: string): void {
        this.dialogState = undefined;
        this.ongoingRequest = true;
        this.addEvent(type, this.timeControl.value ?? new Date());
    }

    shouldAskForComment(): boolean {
        const lastComment = this.currentTimeset?.events.at(-1)?.comment;
        return !lastComment && this.themeService.config.isCommentsAllowed;
    }

    prepareStopWork() {
        if (!this.currentTimeset) return;
        this.dialogState = {
            commentControl: this.shouldAskForComment()
                ? new FormControl()
                : undefined,
            event: this.currentTimeset.events[
                this.currentTimeset.events.length - 1
            ],
            eventIndex: this.currentTimeset.events.length - 1,
            buttonsEnabled: true,
        };
    }

    updateEventComment(index: number, comment: string | undefined) {
        if (!this.currentTimeset) return;
        this.currentTimeset.events[index].comment = comment;
    }

    updateToday(timeset?: Timeset, updateComments = true) {
        this.currentTimeset = timeset;
        this.validateTimestamp(this.timeControl.value);
        this.setAllowedTypes(timeset?.events[timeset.events.length - 1].type);
        if (updateComments) this.updateComments(timeset?.events);
        if (this.currentTimeset) {
            this.eventViews = buildEventView(this.currentTimeset, {
                skipValidation: true,
            });
            const diff =
                Math.trunc(Date.now() / 1000) -
                this.currentTimeset.events.at(-1)!.start;
            if (diff > 10 * 60 * 60) {
                this.showCheckoutReminder();
            }
        } else {
            this.eventViews = [];
        }
        this.ongoingRequest = false;
    }

    toDate(timestamp?: number) {
        return timestamp ? toDate(timestamp) : new Date();
    }

    updateComments(events?: TimesetEvent[]) {
        if (!events) this.comments = [];
        else {
            this.comments = [];
            for (let index = 0; index < events.length; index++) {
                const event = events[index];
                const comment: Comment = {
                    start: event.start,
                    end: event.end,
                    comment: event.comment,
                    indexInTimeset: index,
                };
                this.comments.push(comment);
            }
        }
    }

    editComment(event: Event, timeset: Comment) {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: timeset.comment,
            accept: () => {
                this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmed',
                    detail: 'You have accepted',
                });
            },
            reject: () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Rejected',
                    detail: 'You have rejected',
                });
            },
        });
    }

    loadToday(timeset?: Timeset) {
        this.ongoingRequest = true;
        if (timeset) {
            this.updateToday(timeset);
        } else {
            this.timesetService.getCurrentTimeset().subscribe({
                next: (timeset: Timeset) => {
                    this.updateToday(timeset);
                },
                error: err => {
                    console.error(err);
                    this.updateToday();
                },
            });
        }
    }

    performEvent() {
        if (this.eventMap[this.defaultEventType] === 'STOP_WORK') {
            this.prepareStopWork();
        } else {
            this.ongoingRequest = true;
            return this.uploadTimeEvent(this.eventMap[this.defaultEventType]);
        }
    }

    setAllowedTypes(type: TimesetEventType | undefined) {
        switch (type) {
            case undefined: {
                this.defaultEventType = 'Arbeit Beginnen';
                this.eventTypes = [];

                // this.currentTimeset = {
                //     id: '',
                //     events: [],
                //     sealed: false,
                //     start: 0,
                // };
                break;
            }
            case 'WORK': {
                if (
                    this.currentTimeset?.events.some(
                        key => key.type === 'BREAK'
                    )
                ) {
                    this.defaultEventType = 'Arbeit Beenden';
                    this.eventTypes = [
                        {
                            label: 'Pause Beginnen',
                            command: () => this.uploadTimeEvent('START_BREAK'),
                        },
                        {
                            label: 'Neues Arbeitszeitfenster',
                            command: () => this.uploadTimeEvent('START_WORK'),
                        },
                    ];
                } else {
                    this.defaultEventType = 'Pause Beginnen';
                    this.eventTypes = [
                        {
                            label: 'Arbeit Beenden',
                            command: () => this.prepareStopWork(),
                        },
                        {
                            label: 'Neues Arbeitszeitfenster',
                            command: () => this.uploadTimeEvent('START_WORK'),
                        },
                    ];
                }

                break;
            }
            case 'BREAK': {
                this.defaultEventType = 'Arbeit Beginnen';
                this.eventTypes = [];
                break;
            }
        }
    }

    onSubmit() {
        this.performEvent();
    }

    validateTimestamp(timestamp: Date | null) {
        const rules = this.themeService.config;

        const lastEvent =
            this.currentTimeset?.events[this.currentTimeset.events.length - 1];

        const formTimestamp = toTimestamp(timestamp);
        const currentTimestamp = toTimestamp(new Date());
        const lastTimestamp = lastEvent?.end ?? lastEvent?.start;

        const diff = formTimestamp - (lastTimestamp ?? 0);
        const relDiff = formTimestamp - currentTimestamp;

        this.validationErrors = [];

        if (relDiff > rules.maximumRelativeFutureStampTimeInMinutes * 60) {
            this.validationErrors.push(
                `Zeitpunkt liegt zu weit in der Zukunft - (maximal ${rules.maximumRelativeFutureStampTimeInMinutes} Minuten)`
            );
        } else if (
            Math.abs(relDiff) >
            rules.maximumRelativePastStampTimeInMinutes * 60
        ) {
            this.validationErrors.push(
                `Zeitpunkt liegt zu weit in der Vergangenheit - (maximal ${rules.maximumRelativePastStampTimeInMinutes} Minuten)`
            );
        }
        if (diff > 0 && diff < rules.minimumEventLengthInMinutes * 60) {
            this.validationErrors.push(
                `Zwischen Erfassungen müssen mindestens ${rules.minimumEventLengthInMinutes} Minuten liegen`
            );
        }
        if (diff <= 0) {
            this.validationErrors.push(
                'Überschneidung mit vorheriger Erfassung'
            );
        }
    }

    private putEvent(tempEvent: StampEvent) {
        this.eventService.putEvent(tempEvent).subscribe({
            next: (timeset: Timeset) => {
                this.startAutoTime();
                if (tempEvent.type === 'STOP_WORK') {
                    this.updateToday();
                    this.messageService.add(SUCCESS_MESSAGES['SAVED_TIMESET']);
                } else {
                    this.loadToday(timeset);
                }
            },
            error: err => {
                this.messageService.handleError(err, this.constructor.name);
                this.ongoingRequest = false;
            },
        });
    }

    private addEvent(event: string, time: Date) {
        const temp: StampEventType = getEnumFromString(StampEventType, event);
        const tempEvent: StampEvent = {
            type: temp,
            timestamp: toTimestamp(time),
        };

        return this.putEvent(tempEvent);
    }

    showCheckoutReminder() {
        if ((Date.now() - this.lastCheckoutReminder) / 1000 < 60 * 60) return;
        this.manualTime = true;
        clearInterval(this.autoTimeInterval);
        this.lastCheckoutReminder = Date.now();
        this.confirmationService.confirm({
            message:
                'Deine letzte Aktivität liegt mehr als 10 Stunden zurück. Möchtest du die letzte Arbeitszeit beenden um eine neue zu starten?',
            header: 'Vergessen zu stempeln?',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: 'none',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button-text',
            accept: () => {
                this.uploadTimeEvent('STOP_WORK');
                this.messageService.addBanner({
                    severity: 'warn',
                    summary: 'Letzte Arbeitszeit anpassen',
                    detail: `<span><a href="/times?date=${
                        (this.currentTimeset?.start ?? 0) * 1000
                    }&tid=${
                        this.currentTimeset?.timesetId
                    }">Deine letzte Arbeitszeit</a> wurde nicht korrekt beendet.</span>`,
                });
            },
            reject: () => {
                this.startAutoTime();
            },
        });
    }
}
