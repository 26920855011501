import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {} from '@timebox/custom-primeng/togglebutton';
import {ThemesService} from 'src/app/services/themes.service';

@Component({
    selector: 'app-theme-switch',
    templateUrl: './theme-switch.component.html',
    styleUrls: ['./theme-switch.component.scss'],
})
export class ThemeSwitchComponent implements OnInit {
    themeFormGroup!: FormGroup;

    constructor(private themeService: ThemesService) {}

    ngOnInit(): void {
        this.themeFormGroup = new FormGroup({
            lightTheme: new FormControl<boolean>(false),
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    toggleTheme(event: any) {
        //Um das Theme vernünftig wechseln zu können müssen die properties, die oben bereits gesetzt wurden einmal entfernt werden. Die gesetzten Properties werden beim Theme-Wechsel nicht überschrieben
        // document.documentElement.style.removeProperty('--text-color');
        // this.themeService.toggleTheme(event.checked);
    }
}
