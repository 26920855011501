<div class="comment-container">
    @if (!commentOnly) {
        <div class="comment-time" [style.background-color]="color">
            {{
                toDate(event.start).toLocaleTimeString(['de-de'], {
                    hour: '2-digit',
                    minute: '2-digit'
                })
            }}
        </div>
    }
    <div class="comment-content">
        <textarea
            #textAreaBox
            class="comment-content"
            rows="1"
            inputmode="text"
            [maxlength]="maxCommentLength"
            pInputTextarea
            [placeholder]="placeholder"
            [readonly]="state !== 'edit'"
            (click)="commentFeatureEnabled ? startEditing() : null"
            (touchstart)="commentFeatureEnabled ? startEditing() : null"
            [(ngModel)]="editText"
            (keypress)="detectEnterKey($event)"
            [autoResize]="true"
            [ngStyle]="{
                cursor: commentFeatureEnabled ? 'pointer' : 'default'
            }"
        ></textarea>
        @if (state === 'edit') {
            <span class="char-counter"
                >{{ editText.length }} / {{ maxCommentLength }}</span
            >
        }
    </div>
    @switch (state) {
        @case ('edit') {
            <div class="icon save" (click)="saveComment()">
                <i class="pi pi-check"></i>
            </div>
            <div class="icon cancel" (click)="cancelComment()">
                <i class="pi pi-times"></i>
            </div>
        }
        @case ('saving') {
            <div class="icon">
                <i class="pi pi-spin pi-spinner"></i>
            </div>
        }
        @case ('read') {
            @if (!commentOnly) {
                @if (event.end) {
                    <div class="comment-time" [style.background-color]="color">
                        {{
                            toDate(event.end).toLocaleTimeString(['de-de'], {
                                hour: '2-digit',
                                minute: '2-digit'
                            })
                        }}
                    </div>
                } @else {
                    <div class="time-placeholder"></div>
                }
            }
        }
    }
</div>
