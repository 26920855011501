import {
    Component,
    Input,
    inject,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import {TimesetEventType} from 'src/app/models/EventType';
import {TimesetEvent} from 'src/app/models/api-models/Event';
import {MsgService} from 'src/app/services/msg-service.service';
import {ThemesService} from 'src/app/services/themes.service';
import {EventService} from 'src/app/services/time-api-event.service';
import {toDate} from 'src/utils/converter';
import {colors} from 'src/utils/tools';

type CommentBarMode = 'read' | 'edit' | 'saving';

@Component({
    selector: 'app-comment-bar',
    templateUrl: './comment-bar.component.html',
    styleUrls: ['./comment-bar.component.css'],
})
export class CommentBarComponent implements OnInit {
    @Input() event!: TimesetEvent;
    @Input() eventIndex!: number;
    @Input() commentOnly = false;
    @Output() modeChanged = new EventEmitter<CommentBarMode>();
    @Output() commentChanged = new EventEmitter<string | undefined>();
    eventService = inject(EventService);
    msgService = inject(MsgService);
    themeService = inject(ThemesService);

    _state: CommentBarMode = 'read';

    get state() {
        return this._state;
    }
    set state(state: CommentBarMode) {
        this._state = state;
        this.modeChanged.emit(state);
    }

    originalText: string | undefined;
    editText: string = '';
    maxCommentLength = 0;
    commentFeatureEnabled = false;

    color: string = '';
    placeholder: string = '';

    ngOnInit(): void {
        this.originalText = this.event.comment;
        this.editText = this.event.comment ?? '';
        if (this.event.type === TimesetEventType.BREAK) {
            this.color = this.getBreakDuration()
                ? colors.warnColor
                : colors.breakColor;
            this.placeholder = 'Pause';
            this.commentFeatureEnabled = false;
        } else {
            this.color = this.timesetToLong(this.getTimesetDuration())
                ? colors.warnColor
                : colors.workColor;
            this.placeholder = this.commentFeatureEnabled
                ? 'Kein Kommentar erfasst'
                : 'Arbeit';
        }
    }

    constructor() {
        this.commentFeatureEnabled = this.themeService.config.isCommentsAllowed;
        this.maxCommentLength = this.themeService.config.maxCommentLength;
    }

    timesetToLong(diff: number) {
        return diff > 6 ? true : false;
    }

    toDate(timestamp: number) {
        return toDate(timestamp);
    }

    getBreakDuration() {
        const duration = this.getTimesetDuration();
        return duration > 6 ? true : false;
    }

    getTimesetDuration() {
        const start = this.event.start / 60 / 60;
        const end = this.event.end! / 60 / 60;
        return end - start;
    }

    startEditing() {
        if (this.state === 'edit') return;
        this.editText = this.event.comment ?? '';
        this.state = 'edit';
        return true;
    }
    saveComment() {
        this.state = 'saving';
        this.editText = this.editText.trim();
        this.event.comment = this.editText ? this.editText : undefined;
        this.eventService
            .postComment({
                comment: this.event.comment ?? '',
                indexnumber: this.eventIndex,
            })
            .subscribe({
                next: () => this.saveCompleted(),
                error: err => {
                    this.msgService.handleError(err, this.constructor.name);
                    this.state = 'edit';
                },
            });
    }
    saveCompleted() {
        this.originalText = this.editText ? this.editText : undefined;
        this.state = 'read';
        this.commentChanged.next(this.originalText);
    }
    cancelComment() {
        this.event.comment = this.originalText;
        this.editText = this.originalText ?? '';
        this.state = 'read';
    }

    detectEnterKey(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.saveComment();
            return;
        }
        if (event.key === 'Escape') {
            event.preventDefault();
            this.cancelComment();
            return;
        }
    }
}
