import {Injectable, inject} from '@angular/core';
import {
    HttpClient,
    HttpParams,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {lastValueFrom, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {
    LightTimeset,
    LightTimesetPage,
    Timeset,
    TimesetPage,
} from '../models/api-models/Timeset';
import {UpdateTimesetRequest} from '../models/api-models/UpdateTimesetRequest';
import {AuthService} from './auth.service';
import {toTimestamp} from 'src/utils/converter';

@Injectable({
    providedIn: 'root',
})
export class TimesetService {
    private static REPORT_ENDPOINT = `${environment.apiEndpoint}/reporting`;
    private static ENDPOINT = `${environment.apiEndpoint}/timesets`;
    private static NEW_ENDPOINT = `${environment.apiEndpoint}/timesets/`;

    private authService = inject(AuthService);
    private http = inject(HttpClient);

    requestReporting(year: number, month: number) {
        const request = new HttpRequest(
            'GET',
            `${TimesetService.REPORT_ENDPOINT}/${year}/${month}`
        );

        return new Observable<unknown>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<unknown>
                        >
                    )
                )
                .then(res => subscriber.next(res ?? undefined))
                .catch(err => subscriber.error(err));
        });
    }

    getTimesets(page: number, pageSize: number): Observable<TimesetPage> {
        const request = new HttpRequest(
            'GET',
            `${TimesetService.NEW_ENDPOINT}?page=${page}&pageSize=${pageSize}`
        );

        return new Observable<TimesetPage>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<TimesetPage>
                        >
                    )
                )
                .then(data => subscriber.next(data.body ?? undefined))
                .catch(err => subscriber.error(err));
        });
    }

    getCurrentTimeset() {
        const request = new HttpRequest(
            'GET',
            `${TimesetService.ENDPOINT}/current`
        );

        return new Observable<Timeset>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<Timeset>
                        >
                    )
                )
                .then(timeset => subscriber.next(timeset.body ?? undefined))
                .catch(err => subscriber.error(err));
        });
    }

    getTimesetById(id: string) {
        const request = new HttpRequest(
            'GET',
            `${TimesetService.ENDPOINT}/${id}`
        );

        return new Observable<Timeset>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<Timeset>
                        >
                    )
                )
                .then((data: HttpResponse<Timeset>) =>
                    subscriber.next(data.body ?? undefined)
                )
                .catch(err => subscriber.error(err));
        });
    }

    getTimesetsForDate(selectedDate: Date) {
        const startDate = new Date(selectedDate.setHours(0, 0, 0, 0));
        const startDateTimeStamp = Math.floor(startDate.getTime() / 1000);

        const endDate = new Date(selectedDate.setHours(23, 59, 59, 999));
        const endDateTimeStamp = Math.floor(endDate.getTime() / 1000);

        const params = new HttpParams().appendAll({
            start: startDateTimeStamp,
            end: endDateTimeStamp,
        });
        const request = new HttpRequest('GET', `${TimesetService.ENDPOINT}`, {
            params,
        });

        return new Observable<Timeset[]>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<TimesetPage>
                        >
                    )
                )
                .then((data: HttpResponse<TimesetPage>) => data.body?.timesets)
                .then(data => subscriber.next(data))
                .catch(err => subscriber.error(err));
        });
    }

    getLightTimesetsForMonth(month: number, year: number) {
        const startDateTimeStamp = toTimestamp(
            new Date(year, month, 1, 0, 0, 0)
        );
        const endDateTimeStamp = toTimestamp(
            new Date(year, month, 31, 23, 59, 59)
        );

        const params = new HttpParams().appendAll({
            start: startDateTimeStamp,
            end: endDateTimeStamp,
            lightData: 'true',
            pageSize: '50',
        });
        const request = new HttpRequest('GET', `${TimesetService.ENDPOINT}`, {
            params,
        });

        return new Observable<LightTimeset[]>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<LightTimesetPage>
                        >
                    )
                )
                .then(
                    (data: HttpResponse<LightTimesetPage>) =>
                        data.body?.timesets
                )
                .then(data => subscriber.next(data))
                .catch(err => subscriber.error(err));
        });
    }

    createTimeset(timesetEvent: UpdateTimesetRequest): Observable<Timeset> {
        const request = new HttpRequest<UpdateTimesetRequest>(
            'POST',
            `${TimesetService.ENDPOINT}`,
            timesetEvent
        );
        return new Observable<Timeset>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<Timeset>
                        >
                    )
                )
                .then(data => subscriber.next(data.body ?? undefined))
                .catch(err => subscriber.error(err));
        });
    }

    updateTimeset(
        timesetEvent: UpdateTimesetRequest,
        id: string
    ): Observable<Timeset> {
        const request = new HttpRequest<UpdateTimesetRequest>(
            'PUT',
            `${TimesetService.ENDPOINT}/${id}`,
            timesetEvent
        );
        return new Observable<Timeset>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<Timeset>
                        >
                    )
                )
                .then(data => subscriber.next(data.body ?? undefined))
                .catch(err => subscriber.error(err));
        });
    }

    deleteTimeset(id: string): Observable<void> {
        const request = new HttpRequest(
            'DELETE',
            `${TimesetService.ENDPOINT}/${id}`
        );
        return new Observable<void>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<Timeset>
                        >
                    )
                )
                .then(() => subscriber.next())
                .catch(err => subscriber.error(err));
        });
    }
}
