import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';
import {MsgService} from '../services/msg-service.service';
@Injectable({
    providedIn: 'root',
})
/**
 * The CookieHandler class is used to manage cookies in the application.
 * It allows storing, retrieving and deleting cookies and provides functions for verifying user credentials.
 */
export class CookieHandler {
    /**
     * Creates an Instance of CookieHandler
     */
    constructor(
        private cookie: CookieService,
        private msg: MsgService
    ) {}
    cookies = {
        IDTOKEN: 'timebox-id-token',
        REFRESHTOKEN: 'timebox-refresh-token',
        THEME: 'timebox-theme',
        CONFIG: 'timebox-config',
        ID_EXP_TIME: 'timebox-idttl',
    };
    public idToken: string = this.getCookie(this.cookies.IDTOKEN);
    public refreshToken: string = this.getCookie(this.cookies.REFRESHTOKEN);
    public theme: string = this.getCookie(this.cookies.THEME);
    public config: string = this.getCookie(this.cookies.CONFIG);

    /**
     * Checks whether a user should remain logged in or is still logged in
     *
     */
    public checkForCredentials(): string {
        // TODO: bitte verständlicher (über Ifs) implementieren
        return this.refreshToken
            ? this.idToken
                ? 'noLogin'
                : 'refreshLogin'
            : this.idToken
              ? 'noLogin'
              : 'logOut';
    }

    /**
     * Saves a single Cookie
     * @param COOKIE Cookie name
     * @param COOKIE_VALUE Cookie Value
     * @param secure Is Cookie Secured?
     * @param expires Expires in (@example new Date(Date.now() + 60 * 60 * 1000) //60min)
     * @param save if cookie should be saved
     */
    public setCookie(
        COOKIE: string,
        COOKIE_VALUE: string,
        secure: boolean,
        expires: number | Date | undefined,
        save: boolean
    ) {
        this.cookie.set(COOKIE, COOKIE_VALUE, {
            secure: secure,
            expires: save ? expires : new Date(Date.now() + 60 * 1000 * 60),
        });
    }

    /**
     * Delete all Cookies
     */
    public deleteCookies() {
        this.cookie.deleteAll();
    }

    /**
     * Get a specific Cookie
     * @param name Cookie Name
     * @returns Cookie Value
     */
    public getCookie(name: string) {
        return this.cookie.get(name);
    }
}
