import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Observable, lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SelfServiceService {
    private static ENDPOINT = environment.apiEndpoint;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    public saveSelfService(
        email: string,
        version: string,
        subject: string,
        description: string
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const request = new HttpRequest<any>(
            'POST',
            `${SelfServiceService.ENDPOINT}/selfservice`,
            {
                email: email,
                frontendVersion: version,
                subject: subject,
                description: description,
            }
        );
        return new Observable<void>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req => lastValueFrom(this.http.request(req)))
                .then(() => subscriber.next());
        });
    }
}
