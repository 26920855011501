import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
    DynamicDialogRef,
    DialogService,
} from '@timebox/custom-primeng/dynamicdialog';
import {MsgService} from 'src/app/services/msg-service.service';
import {AuthService} from 'src/app/services/auth.service';
import {Subscription} from 'rxjs';
import {
    ERROR_MESSAGES,
    SUCCESS_MESSAGES,
} from 'src/app/services/toast-messages';

@Component({
    selector: 'app-login-screen',
    templateUrl: './login-screen.component.html',
    styleUrls: ['./login-screen.component.css', '../auth.styles.css'],
    providers: [DialogService],
    encapsulation: ViewEncapsulation.None,
})
export class LoginScreenComponent implements OnInit, OnDestroy {
    constructor(
        private api: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public dialogService: DialogService,
        private messageService: MsgService
    ) {}

    private subscription: Subscription | undefined;

    private retUrl: string = '/';
    loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        stayIn: new FormControl(false),
    });

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe(params => {
            this.retUrl = params.get('retUrl') ?? '/';
            if (params.get('challengeSuccess')) {
                this.messageService.add(SUCCESS_MESSAGES['PASSWORD_SAVED']);
            }
        });
        this.subscription = this.api.authenticationSignalAsync.subscribe(
            sig => {
                if (sig) this.router.navigate([this.retUrl]);
            }
        );
    }
    ongoingRequest = false;
    errorMessage: string | undefined;

    stayIn = false;

    htmlArguments = {
        header: 'Timebox Login',
        input1: 'Email Adresse',
        input2: 'Passwort',
    };

    ref: DynamicDialogRef | undefined;

    login() {
        this.ongoingRequest = true;
        this.api
            .signIn(
                this.loginForm.controls.username.value ?? '',
                this.loginForm.controls.password.value ?? '',
                this.loginForm.controls.stayIn.value ?? false
            )
            .then(res => {
                this.ongoingRequest = false;
                switch (res) {
                    case 'challenge':
                        this.router.navigate(['/challenge']);
                        break;
                    case 'wrong':
                        this.loginForm.reset({
                            username: this.loginForm.controls.username.value,
                            stayIn: this.loginForm.controls.stayIn.value,
                        });
                        this.messageService.add(
                            ERROR_MESSAGES['WRONG_USERNAME_PASSWORD']
                        );
                        break;
                }
            });
        // .subscribe({
        //     next: response => {
        //         if (
        //             ['NEW_PASSWORD_REQUIRED'].includes(
        //                 response.ChallengeName ?? ''
        //             ) &&
        //             this.loginForm.controls.stayIn.value
        //         ) {
        //             this.messageService.add({
        //                 severity: 'info',
        //                 summary: 'Achtung',
        //                 detail: 'Aufgrund der Erstanmeldung wurden die Daten nicht gespeichert',
        //             });
        //         } else if (
        //             ['NEW_PASSWORD_REQUIRED'].includes(
        //                 response.ChallengeName ?? ''
        //             )
        //         ) {
        //             this.router.navigate(['/challenge']);
        //             this.ongoingRequest = false;
        //         } else {
        //             this.router.navigate(['/today']);
        //             this.ongoingRequest = false;
        //         }
        //         this.loginForm.reset();
        //     },
        //     error: err => {
        //         this.messageService.handleError(err, this.constructor.name);
        //         this.ongoingRequest = false;
        //         this.loginForm.reset({
        //             username: this.loginForm.controls.username.value,
        //             stayIn: this.loginForm.controls.stayIn.value,
        //         });
        //     },
        // });
    }

    ngOnDestroy() {
        if (this.ref) {
            this.ref.close();
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
