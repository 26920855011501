export const SUCCESS_MESSAGES = {
    REPORT_REQUESTED: {
        summary: 'Bericht angefordert!',
        detail: 'Der Bericht wird erstellt und Dir per E-Mail zugeschickt',
        severity: 'success',
    },
    SAVED_TIMESET: {
        summary: 'Gespeichert!',
        detail: 'Arbeitszeit wurde erfolgreich aktualisiert',
        severity: 'success',
    },
    CREATED_TIMESET: {
        summary: 'Gespeichert!',
        detail: 'Neue Arbeitszeit wurde erfolgreich angelegt',
        severity: 'success',
    },
    PASSWORD_SAVED: {
        summary: 'Gespeichert!',
        detail: 'Passwort wurde erfolgreich gespeichert',
        severity: 'success',
    },
    MAIL_SEND: {
        severity: 'success',
        summary: 'E-Mail verschickt!',
        detail: 'Die E-Mail zum Zurücksetzen des Passwortes verschickt',
    },
    INFO: {
        severity: 'info',
        summary: 'Vielen Dank!',
        detail: 'Wir haben Ihre Anfrage erhalten und werden uns zeitnah darum kümmern!',
    },
    DELETE_TIMESET: {
        summary: 'Entfernt!',
        detail: 'Arbeitszeit wurde erfolgreich gelöscht',
        severity: 'success',
    },
};

export const ERROR_MESSAGES = {
    WRONG_USERNAME_PASSWORD: {
        summary: 'Versuch es bitte erneut',
        detail: 'Ungültiger Nutzername oder Kennwort',
        severity: 'error',
    },
    OLDER_TIMESET: {
        summary: 'Ungültige Buchung',
        detail: 'Der eingegebene Zeitpunkt ist älter als das letzte Event',
        severity: 'error',
    },
    FUTURE_TIMESTAMP: {
        summary: 'Ungültige Buchung',
        detail: 'Der eingegebene Zeitpunkt liegt in der Zukunft',
        severity: 'error',
    },
    TIMESTAMP_RANGE: {
        summary: 'Ungültige Buchung',
        detail: 'Der eingegebene Zeitpunkt liegt außerhalb des erlaubten',
        severity: 'error',
    },
    MUSTBE_GREATER: {
        summary: 'Ungültige Buchung',
        detail: 'Für den gewünschten Zeitraum existiert bereits eine Buchung',
        severity: 'error',
    },
    INVALID_CHANGE: {
        summary: 'Ungültige Buchung',
        detail: 'Der gewünschte Vorgangstyp ist nicht buchbar, wähle bitte einen anderen',
        severity: 'error',
    },
    TIMESET_INTERSECT: {
        summary: 'Fehlerhafte Buchung',
        detail: 'Die Angegebenen Zeiträume überschneiden sich mit anderen Buchungen',
        severity: 'error',
    },
    EVENT_TO_SHORT: {
        summary: 'Fehlerhafte Buchung',
        detail: 'Einige angegebenen Zeiträume unterschreiten die Mindestlänge',
        severity: 'error',
    },
    WENT_WRONG: {
        summary: 'Etwas ist schiefgelaufen',
        detail: 'Ein unbekannter Fehler ist aufgetreten, bitte wende Dich an den Support',
        severity: 'error',
    },
};
