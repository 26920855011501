import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import {Timeset} from 'src/app/models/api-models/Timeset';
import {buildEventView, getEventTypeColor, colors} from 'src/utils/tools';
import {TimesetEventType} from '../../models/EventType';
import {TimesetEvent} from '../../models/api-models/Event';
import {timestampToSimpleTime, toDate, toTimestamp} from 'src/utils/converter';
import {ThemesService} from 'src/app/services/themes.service';
import {EventView} from '../../models/views/EventView';
// import {toTimestamp} from '../../../utils/converter';

interface EventDuration {
    duration: number;
    label: string;
}

@Component({
    selector: 'app-timeset-edit',
    templateUrl: './timeset-edit.component.html',
    styleUrls: ['./timeset-edit.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TimesetEditComponent implements OnInit, OnChanges {
    //Das ausgewählte Timeset, welches wir per HTML-Input hineingeben
    @Input() timeset!: Timeset;
    @Output() timesetChange = new EventEmitter<Timeset>();

    //Bool, um anzugeben ob wir das Timeset nur anschauen oder tatsächlich bearbeiten
    @Input() editMode: boolean = false;
    @Output() saveTrigger = new EventEmitter<Timeset>();
    @Output() cancelTrigger = new EventEmitter<Event>();

    @ViewChild('fieldsetToggle', {read: ElementRef}) fieldsetToggle:
        | ElementRef
        | undefined;
    fieldsetToggleState: boolean = true;
    colors = colors;

    themeService = inject(ThemesService);

    isSaveAllowed = false;
    eventViews: EventView[] = [];

    editStartDate: Date | undefined;
    editEndDate: Date | undefined;
    dropDownValue: number | undefined = 30;
    addingErrorMessage: string | undefined;
    addingWarnMessage: string | undefined;

    durationDropDownItems: EventDuration[] = [
        {duration: 30, label: '30 m'},
        {duration: 60, label: '1 h'},
        {duration: 90, label: '90 m'},
        {duration: 120, label: '2 h'},
    ];

    originalStartEnd: {start: number; end: number} = {start: 0, end: 0};

    static readonly MAX_BOUNDARY_HOURS = 10;

    eventBoundaries: {min: Date; max: Date}[] = [];

    eventEditErrors: boolean[] = [];

    ngOnInit() {
        this.initiatePickers();
        this.eventViews = this.buildEventView();
        this.buildEventBoundaries();
        this.originalStartEnd = {
            start: this.timeset.start,
            end: this.timeset.end!,
        };
        this.eventEditErrors = [];
    }

    diffrentDate(timeset: Timeset) {
        const isSameDay = (timestamp1: number, timestamp2: number): boolean => {
            const date1 = new Date(timestamp1 * 1000);
            const date2 = new Date(timestamp2 * 1000);

            return (
                date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate()
            );
        };

        if (isSameDay(timeset.start, timeset.end!)) {
            return false;
        }

        return true;
    }

    buildEventBoundaries() {
        this.eventBoundaries = this.timeset
            ? this.timeset.events.map((_, i) => this.calcEventBoundary(i))
            : [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['timeset']) {
            if (changes['timeset'].currentValue) {
                this.ngOnInit();
            } else {
                this.eventViews = [];
            }
        }

        if (changes['editMode']) {
            this.editMode = changes['editMode'].currentValue;
        }
    }

    initiatePickers() {
        if (this.timeset) {
            this.editStartDate = toDate(
                this.timeset.events.at(-1)!.end ??
                    this.timeset.events.at(-1)!.start
            );
            this.applyDropdownEndValue();
        }
    }

    applyDropdownEndValue() {
        if (this.editStartDate && this.dropDownValue) {
            this.editEndDate = new Date(
                this.editStartDate.getTime() + this.dropDownValue * 60 * 1000
            );
        }
    }

    setAddPanels(e?: TimesetEvent) {
        if (e) {
            this.editStartDate = toDate(e.end!);
        } else {
            this.dropDownValue = this.dropDownValue ?? 30;
            this.editStartDate = toDate(
                this.timeset.start - this.dropDownValue * 60
            );
        }
        this.manualStartSet();
        this.setFieldsetState(false);
    }

    manualStartSet() {
        this.applyDropdownEndValue();
        this.validateManualTime();
    }

    manualEndSet() {
        this.dropDownValue = undefined;
        this.validateManualTime();
    }

    calcEventBoundary(index: number) {
        const preEvent =
            index > 0 ? this.timeset.events.at(index - 1) : undefined;
        const postEvent = this.timeset.events.at(index + 1);
        return {
            min: preEvent
                ? toDate(preEvent.start + this.getMinEventSecs())
                : this.getMinRelativeDate(),
            max: postEvent
                ? toDate(postEvent.end! - this.getMinEventSecs())
                : this.getMaxRelativeDate(),
        };
    }
    private getMinEventSecs() {
        return this.themeService.config.minimumEventLengthInMinutes * 60;
    }

    private getMaxRelativeDate() {
        return toDate(
            this.originalStartEnd.end +
                TimesetEditComponent.MAX_BOUNDARY_HOURS * 60 * 60
        );
    }
    private getMinRelativeDate() {
        return toDate(
            this.originalStartEnd.start -
                TimesetEditComponent.MAX_BOUNDARY_HOURS * 60 * 60
        );
    }

    validateManualTime() {
        this.addingErrorMessage = undefined;
        this.addingWarnMessage = undefined;
        if (!this.timeset || !this.editStartDate || !this.editEndDate) {
            return;
        }

        const addStart = toTimestamp(this.editStartDate ?? null);
        const addEnd = toTimestamp(this.editEndDate ?? null);

        const overlappingEvents = this.timeset.events.filter(
            event => event.start < addEnd && event.end! > addStart
        );
        const newEvents = this.buildNewFromOverlapping(overlappingEvents, {
            start: addStart,
            end: addEnd,
        });
        const minTime = this.getMinEventSecs();
        const tooShortEvents = newEvents.filter(
            e => Math.trunc(e.end! - e.start) < minTime
        );
        if (tooShortEvents.length > 0) {
            this.addingErrorMessage =
                `Entstehende Zeiträume unterschreiten die Mindestdauer von ${
                    minTime / 60
                } Minuten: ` +
                tooShortEvents
                    .map(
                        e =>
                            `${timestampToSimpleTime(
                                e.start
                            )} - ${timestampToSimpleTime(e.end!)}`
                    )
                    .join(', ');
            return;
        }
        if (
            overlappingEvents.some(e => e.start <= addStart && e.end! <= addEnd)
        ) {
            this.addingWarnMessage =
                'Mindestens ein Zeitraum wird vollständig überschrieben.';
        }
    }

    WorkType = TimesetEventType.WORK;
    BreakType = TimesetEventType.BREAK;

    buildNewFromOverlapping(
        overlappingEvents: TimesetEvent[],
        insert: {start: number; end: number}
    ) {
        const newEvents: TimesetEvent[] = [];

        for (const event of overlappingEvents) {
            if (event.start < insert.start) {
                newEvents.push({
                    type: event.type,
                    start: event.start,
                    end: insert.start,
                    comment: event.comment,
                });
            }
            if (event.end! > insert.end) {
                newEvents.push({
                    type: event.type,
                    start: insert.end,
                    end: event.end,
                    comment: event.comment,
                });
            }
        }
        return newEvents;
    }

    setFieldsetState(collapsed: boolean) {
        if (this.fieldsetToggle) {
            if (this.fieldsetToggleState !== collapsed) {
                this.fieldsetToggle.nativeElement.click();
            }
        }
    }

    toDate(timestamp?: number) {
        return timestamp ? toDate(timestamp) : new Date();
    }

    longTimeset() {
        return this.timesetToLong(this.getTimesetDuration());
    }

    getTimesetDuration() {
        const start = this.timeset.start / 60 / 60;
        const end = this.timeset.end! / 60 / 60;
        return end - start;
    }

    timesetToLong(diff: number) {
        return diff > 11;
    }

    addEventToTimeset(type: TimesetEventType) {
        if (this.timeset && this.editStartDate && this.editEndDate) {
            this.setFieldsetState(true);
            const addStart = toTimestamp(this.editStartDate ?? null);
            const addEnd = toTimestamp(this.editEndDate ?? null);
            const newEvents: TimesetEvent[] = [
                {
                    type: type,
                    start: addStart,
                    end: addEnd,
                },
            ];

            const overlappingEvents = this.timeset.events.filter(
                event => event.start < addEnd && event.end! > addStart
            );
            if (overlappingEvents.length > 0) {
                newEvents.push(
                    ...this.buildNewFromOverlapping(overlappingEvents, {
                        start: addStart,
                        end: addEnd,
                    })
                );

                const firstIndex = this.timeset.events.findIndex(
                    e => e.start === overlappingEvents[0].start
                );

                this.timeset.events.splice(
                    firstIndex,
                    overlappingEvents.length,
                    ...newEvents
                );
            } else {
                this.timeset.events.push(...newEvents);
            }
            this.eventEditErrors = [];
            this.applyTimeset();
        }
    }

    applyTimeset() {
        this.timeset.events.sort((a, b) => a.start - b.start);
        this.timeset.start = this.timeset.events.at(0)!.start;
        if (this.timeset.end !== this.timeset.events.at(-1)!.end) {
            this.timeset.end = this.timeset.events.at(-1)!.end;
            this.initiatePickers();
        }

        this.eventViews = this.buildEventView();
        this.buildEventBoundaries();
        this.validateManualTime();
        this.emitTimesetChange();
    }

    removeEventOnIndex(index: number) {
        const postEvent = this.timeset?.events?.at(index + 1);
        const preEvent =
            index > 0 ? this.timeset?.events?.at(index - 1) : undefined;

        if (postEvent && preEvent) {
            postEvent.start = preEvent.end!;
        }

        this.timeset?.events.splice(index, 1);
        this.eventEditErrors.splice(index, 1);
        this.applyTimeset();
    }

    save() {
        if (!this.timeset) {
            return;
        }
        this.saveTrigger.emit(this.timeset);
    }

    buildEventView() {
        return this.timeset ? buildEventView(this.timeset) : [];
    }

    cancel(event: Event) {
        this.cancelTrigger.emit(event);
    }

    getWorkColor() {
        return getEventTypeColor(TimesetEventType.WORK);
    }

    getBreakColor() {
        return getEventTypeColor(TimesetEventType.BREAK);
    }

    emitTimesetChange() {
        this.timesetChange.emit(this.timeset);
    }

    checkSaveButton() {
        return this.eventEditErrors.some(e => e === true);
    }

    handleEventUpdate(index: number, event: TimesetEvent) {
        const preEvent =
            index > 0 ? this.timeset?.events?.at(index - 1) : undefined;
        const postEvent = this.timeset?.events?.at(index + 1);

        if (preEvent) {
            preEvent.end = event.start;
        }
        if (postEvent) {
            postEvent.start = event.end!;
        }
        this.applyTimeset();
    }

    protected readonly JSON = JSON;
}
