<div
    class="screen-container"
    [style.background-image]="'url(' + backgroundImage + ')'"
>
    <div class="content-container">
        @if (showLoading) {
        <div class="loading-container">
            <p-progressSpinner></p-progressSpinner>
        </div>
        }
        <img [src]="logoUrl" [height]="logoHeight">
        <h1 class="auth-title">{{ title }}</h1>
        <h3 class="auth-subtitle">{{ subtitle ?? '' }}</h3>
        <ng-content></ng-content>
    </div>
</div>
