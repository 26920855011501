import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SelfServiceService} from '../../services/self-service.service';
import pgkJson from '../../../../package.json';
import {DynamicDialogRef} from '@timebox/custom-primeng/dynamicdialog';
import {MsgService} from '../../services/msg-service.service';
import {NoWhitespaceValidator} from '../../../utils/customValidatorFunctions';
import {AuthService} from 'src/app/services/auth.service';
import {SUCCESS_MESSAGES} from 'src/app/services/toast-messages';

@Component({
    selector: 'app-self-service',
    templateUrl: './self-service.component.html',
    styleUrls: ['./self-service.component.scss'],
})
export class SelfServiceComponent implements OnInit {
    ongoingRequest = false;
    selfServiceForm = new FormGroup({
        userEmail: new FormControl<string>('', [
            Validators.required,
            Validators.email,
        ]),
        subject: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
            NoWhitespaceValidator(),
        ]),
        description: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(1000),
            NoWhitespaceValidator(),
        ]),
    });
    constructor(
        private selfService: SelfServiceService,
        private authService: AuthService,
        private dialogRef: DynamicDialogRef,
        private messageService: MsgService
    ) {}

    ngOnInit() {
        const userEmail = this.authService.user?.email;
        if (userEmail) {
            this.selfServiceForm.controls['userEmail'].setValue(userEmail);
            this.selfServiceForm.controls['userEmail'].disable();
        }
    }

    submitSelfService() {
        this.ongoingRequest = true;
        const formValue = this.selfServiceForm.getRawValue();
        const email = formValue.userEmail;
        const subject = formValue.subject;
        const description = formValue.description;
        if (subject && description && email) {
            this.selfService
                .saveSelfService(email, pgkJson.version, subject, description)
                .subscribe({
                    next: () => {
                        this.ongoingRequest = false;
                        this.messageService.add(SUCCESS_MESSAGES['INFO']);
                        this.close();
                    },
                    error: err => {
                        this.messageService.handleError(
                            err,
                            this.constructor.name
                        );
                    },
                });
        }
    }

    close() {
        this.dialogRef.close();
    }
}
