export enum StampEventType {
    START_WORK = 'START_WORK',
    START_BREAK = 'START_BREAK',
    STOP_WORK = 'STOP_WORK',
}

export enum TimesetEventType {
    WORK = 'WORK',
    BREAK = 'BREAK',
}
