import {HttpRequest} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {
    fetchAuthSession,
    signOut,
    signIn,
    confirmSignIn,
    resetPassword,
    confirmResetPassword,
    fetchUserAttributes,
    AuthError,
} from 'aws-amplify/auth';
import {CookieStorage} from 'aws-amplify/utils';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
import {ReplaySubject} from 'rxjs';
import {MsgService} from './msg-service.service';
import {SUCCESS_MESSAGES} from './toast-messages';

export interface TimeboxUser {
    email: string | undefined;
    customerId: string | undefined;
    givenName: string | undefined;
    familyName: string | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private msgService = inject(MsgService);
    private _user: TimeboxUser | undefined;
    public get user(): TimeboxUser | undefined {
        return this._user;
    }

    // Authentication signal
    private authSignal: boolean | undefined;
    public get authenticationSignal(): boolean | undefined {
        return this.authSignal;
    }
    private set authenticationSignal(value: boolean) {
        this.authSignal = value;
        this.authenticationSignalAsync.next(value);
    }
    public authenticationSignalAsync = new ReplaySubject<boolean>();

    public async signHttpRequest<T>(
        req: HttpRequest<T>,
        options?: {omitSignal?: boolean}
    ): Promise<HttpRequest<T>> {
        const session = await this.checkAuthSessionInternal(
            options?.omitSignal
        );
        const headers = req.headers.set(
            'Authorization',
            session.tokens?.idToken?.toString() ?? ''
        );
        return req.clone({headers});
    }

    private async loadUser() {
        if (this.authSignal) {
            const attributes = await fetchUserAttributes();
            this._user = {
                email: attributes['email'],
                customerId: attributes['custom:CUSTOMER_ID'],
                givenName: attributes['given_name'],
                familyName: attributes['family_name'],
            };
        } else {
            this._user = undefined;
        }
    }

    public async checkAuthSession() {
        await this.checkAuthSessionInternal();
    }

    private async checkAuthSessionInternal(omitSignal?: boolean) {
        const session = await fetchAuthSession();
        const isAuth = session.userSub ? true : false;
        if (isAuth) {
            await this.loadUser();
        }
        if (!omitSignal) {
            this.authenticationSignal = session.userSub ? true : false;
        }
        return session;
    }

    public async signIn(username: string, password: string, stay?: boolean) {
        if (stay) {
            cognitoUserPoolsTokenProvider.setKeyValueStorage(
                new CookieStorage({sameSite: 'none'})
            );
        } else {
            cognitoUserPoolsTokenProvider.setKeyValueStorage(
                new CookieStorage({expires: NaN, sameSite: 'strict'})
            );
        }

        const signInResponse = await signIn({
            username,
            password,
            options: {
                authFlowType: 'USER_SRP_AUTH',
            },
        }).catch(e => {
            if (e instanceof AuthError) {
                return e;
            }
            console.error(e);
            throw e;
        });

        if (signInResponse instanceof AuthError) {
            // TODO different error handlings
            console.error(signInResponse);
            return 'wrong';
        }

        if (signInResponse.isSignedIn) {
            await this.checkAuthSession();
            return 'done';
        } else {
            switch (signInResponse.nextStep.signInStep) {
                case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
                    return 'challenge';
                default:
                    console.warn(
                        'Unknown sign in step',
                        signInResponse.nextStep.signInStep
                    );
                    break;
            }
        }
        return 'unknown';
    }

    public async resetPassword(username: string) {
        await resetPassword({
            username,
        });
    }

    public async confirmResetPassword(
        username: string,
        newPassword: string,
        confirmationCode: string
    ) {
        try {
            await confirmResetPassword({
                username,
                newPassword,
                confirmationCode,
            });
            this.msgService.add(SUCCESS_MESSAGES['PASSWORD_SAVED']);
            await this.signIn(username, newPassword);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public async setNewRequiredPassword(newPassword: string) {
        try {
            const result = await confirmSignIn({
                challengeResponse: newPassword,
            });
            this.msgService.add(SUCCESS_MESSAGES['PASSWORD_SAVED']);
            if (result.isSignedIn) await this.checkAuthSession();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public async signOut() {
        await signOut();
        this.authenticationSignal = false;
    }
}
