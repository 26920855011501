import {Injectable, inject} from '@angular/core';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, lastValueFrom} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Timeset} from '../models/api-models/Timeset';
import {StampEvent} from '../models/api-models/Event';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private static ENDPOINT = `${environment.apiEndpoint}/events`;
    private authService = inject(AuthService);
    private http = inject(HttpClient);

    putEvent(event: StampEvent): Observable<Timeset> {
        const request = new HttpRequest<StampEvent>(
            'PUT',
            `${EventService.ENDPOINT}`,
            event
        );
        return new Observable<Timeset>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<Timeset>
                        >
                    )
                )
                .then(data => subscriber.next(data.body ?? undefined))
                .catch(err => subscriber.error(err));
        });
    }

    postComment(commentReq: {comment: string; indexnumber: number}) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const request = new HttpRequest<any>(
            'POST',
            `${EventService.ENDPOINT}/comment`,
            commentReq
        );
        return new Observable<void>(subscriber => {
            this.authService
                .signHttpRequest(request)
                .then(req =>
                    lastValueFrom(
                        this.http.request(req) as Observable<
                            HttpResponse<Timeset>
                        >
                    )
                )
                .then(() => subscriber.next())
                .catch(err => subscriber.error(err));
        });
    }
}
