import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {CookieStorage} from 'aws-amplify/utils';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
import {Amplify} from 'aws-amplify';
import {environment} from './environments/environment';
Amplify.configure({
    Auth: {
        Cognito: {
            //  Amazon Cognito User Pool ID
            userPoolId: environment.cognito.userPoolId,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: environment.cognito.clientId,
        },
    },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
