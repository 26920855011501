<div class="main">
    <div class="content" #appContent>
        @if (showMenu) {
        <div class="limited-content">
            <app-headerbar></app-headerbar>
        </div>
        }
        <p-toast
            [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"
        ></p-toast>
        <p-messages class="limited-content" key="globalBanner" [escape]="false"></p-messages>
        <div class="limited-content routing-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- <div *ngIf="showMenu"> -->
    <footer
        [ngStyle]="{
            'background-color': showMenu ? 'var(--surface-a)' : 'transparent'
        }"
    >
        <section class="left">
            @if (showDebug) {
            <div
                (click)="nextDebug()"
                style="
                    background-color: yellow;
                    padding: 1px 5px;
                    font-size: 10pt;
                    cursor: default;
                    text-align: center;
                "
            >
                {{ debugInfo[showDebug] }}
            </div>
            }
        </section>
        <div id="impressum">
            <a
                routerLink="/impressum"
                class="p-button-text"
                [ngStyle]="{
                    color: showMenu
                        ? 'var(--text-color)'
                        : 'var(--text-color-white)',
                    'background-color': 'var(--surface-color)',
                    'font-size': '1rem'
                }"
                >Impressum & Datenschutz</a
            >
        </div>
        @if(showMenu){
        <div id="selfService">
            <p
                styleClass="p-button-secondary p-button-text"
                (click)="showSelfService()"
                [ngStyle]="{
                    color: showMenu
                        ? 'var(--text-color)'
                        : 'var(--text-color-white)',
                    'background-color': 'var(--surface-color)',
                    'font-size': '1.5rem'
                }"
            >
                <i class="pi pi-exclamation-circle"></i>
            </p>
        </div>
        <br />
        }
        <section class="right">
            <!-- <app-theme-switch></app-theme-switch> -->
        </section>
    </footer>
    <!-- </div> -->
</div>
<p-dialog
    header="Header"
    [(visible)]="showPersonaldataLoading"
    [modal]="true"
    [style]="{width: '20rem'}"
    [contentStyle]="{padding: '1.5rem'}"
    [draggable]="false"
    [resizable]="false"
    [showHeader]="false"
    [closable]="false"
>
    <div style="width: 100%; display: flex; align-items: center; flex-direction: column;">
      <p-progressSpinner></p-progressSpinner>
      <p>Einstellungen werden geladen</p>
    </div>
</p-dialog>
