<div class="full-width">
    <section
        style="display: flex; flex-direction: column-reverse; flex-wrap: wrap"
    >
        <section
            style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: var(--surface-d);
            "
        >
            <span>
                Von:
                {{
                    toDate(timeset.start).toLocaleDateString(['de-de'], {
                        month: 'long',
                        day: 'numeric'
                    })
                }}
            </span>
            <br />
            <span>
                Bis:
                {{
                    toDate(timeset.end).toLocaleDateString(['de-de'], {
                        month: 'long',
                        day: 'numeric'
                    })
                }}
            </span>
        </section>
        @if (longTimeset() === true) {
            <span [style.color]="colors.errorColor" style="align-self: center">
                Gesamtlänge von 11 Stunden wurde überschritten
                <i class="pi pi-exclamation-triangle"></i>
            </span>
        }
    </section>
    <app-event-view
        [eventViews]="eventViews"
        style="flex-grow: 1"
    ></app-event-view>
    <p-dataView #eventsDataView [value]="timeset.events">
        <ng-template pTemplate="list" let-events>
            <div>
                @if (editMode) {
                    <div class="event-add-container">
                        <i
                            class="pi pi-plus-circle"
                            style="
                                cursor: pointer;
                                transform: translateX(
                                    -100%
                                ); /*Benötigen wir um Button um eigene Breite nach links zu verschieben und somit wieder zu zentrieren*/
                            "
                            (click)="setAddPanels()"
                        ></i>
                    </div>
                }
                @for (e of events; track e; let index = $index) {
                    <div style="display: flex; align-items: center">
                        <app-event-row
                            style="flex-grow: 1"
                            [event]="e"
                            [editMode]="editMode"
                            (eventChange)="handleEventUpdate(index, $event)"
                            [boundary]="eventBoundaries[index]"
                            [isFirstEvent]="index === 0"
                            [isLastEvent]="index === events.length - 1"
                            (hasError)="eventEditErrors[index] = $event"
                            [minimumEventSecs]="
                                themeService.config
                                    .minimumEventLengthInMinutes * 60
                            "
                        ></app-event-row>
                        <div style="display: flex; flex-direction: column">
                            @if (editMode) {
                                @if (eventEditErrors[index]) {
                                    <i
                                        class="pi pi-exclamation-triangle"
                                        [style]="{
                                            color: 'var(--orange-500)',
                                            margin: '0.5rem',
                                            cursor: 'default'
                                        }"
                                    ></i>
                                }
                                <i
                                    class="pi pi-minus-circle"
                                    (click)="
                                        timeset.events.length > 1
                                            ? removeEventOnIndex(index)
                                            : null
                                    "
                                    [style]="
                                        timeset.events.length > 1
                                            ? {
                                                  color: 'var(--orange-500)',
                                                  margin: '0.5rem',
                                                  cursor: 'pointer'
                                              }
                                            : {
                                                  color: 'var(--orange-500)',
                                                  margin: '0.5rem',
                                                  cursor: 'default',
                                                  opacity: '0.4'
                                              }
                                    "
                                ></i>
                            }
                        </div>
                    </div>
                    @if (editMode) {
                        <div class="event-add-container">
                            <i
                                class="pi pi-plus-circle"
                                style="
                                    cursor: pointer;
                                    transform: translateX(-100%);
                                "
                                (click)="setAddPanels(e)"
                            ></i>
                        </div>
                    }
                }
            </div>
        </ng-template>
    </p-dataView>
    @if (editMode) {
        <p-fieldset
            [style]="{
                'margin-top': '4rem'
            }"
            [(collapsed)]="fieldsetToggleState"
            [toggleable]="true"
            transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
        >
            <ng-template pTemplate="header">
                <span #fieldsetToggle class="font-bold"
                    >Zeitfenster einfügen</span
                >
            </ng-template>
            <div class="add-grid">
                <h4 style="grid-area: h-start">Start</h4>
                <p-calendar
                    [tabIndent]="1"
                    [style]="{'grid-area': 'p-start'}"
                    [timeOnly]="true"
                    [inline]="true"
                    [panelStyle]="{
                        border: 'none',
                        'max-width': '15rem',
                        'min-width': '15rem'
                    }"
                    [(ngModel)]="editStartDate"
                    (ngModelChange)="manualStartSet()"
                ></p-calendar>
                <h4 style="grid-area: h-dur">Dauer</h4>
                <p-dropdown
                    [options]="durationDropDownItems"
                    appendTo="body"
                    optionLabel="label"
                    optionValue="duration"
                    placeholder="-"
                    [style]="{
                        margin: '1rem',
                        'grid-area': 'p-dur',
                        'min-width': '7rem',
                        'max-width': '7rem'
                    }"
                    [(ngModel)]="dropDownValue"
                    (onChange)="applyDropdownEndValue()"
                ></p-dropdown>
                <h4 style="grid-area: h-end">Ende</h4>
                <p-calendar
                    [tabIndent]="2"
                    [style]="{'grid-area': 'p-end'}"
                    [panelStyle]="{
                        border: 'none',
                        'max-width': '15rem',
                        'min-width': '15rem'
                    }"
                    [timeOnly]="true"
                    [inline]="true"
                    [(ngModel)]="editEndDate"
                    (ngModelChange)="manualEndSet()"
                ></p-calendar>
            </div>
            <div class="add-buttons">
                <p-button
                    [text]="false"
                    icon="pi pi-plus-circle"
                    (onClick)="addEventToTimeset(WorkType)"
                    [disabled]="!!addingErrorMessage"
                    label="Arbeit"
                ></p-button>
                <span style="width: 1rem"></span>
                <p-button
                    [text]="false"
                    icon="pi pi-plus-circle"
                    (onClick)="addEventToTimeset(BreakType)"
                    [disabled]="!!addingErrorMessage"
                    label="Pause"
                ></p-button>
            </div>
            <!-- <div class="add-container">
                <div style="display: flex; justify-items: stretch">
                    <div class="desc-box"></div>

                    <div class="desc-box"></div>
                </div>

                <div class="desc-box"></div>
            </div> -->
            @if (addingErrorMessage && !fieldsetToggleState) {
                <p style="color: var(--orange-500)">{{ addingErrorMessage }}</p>
            }
            @if (addingWarnMessage && !fieldsetToggleState) {
                <p style="color: rgb(181, 117, 0)">{{ addingWarnMessage }}</p>
            }
        </p-fieldset>

        <div class="save-cancel-container">
            <p-button
                label="Verwerfen"
                icon="pi pi-times-circle"
                iconPos="right"
                (onClick)="cancel($event)"
            ></p-button>
            <span style="height: 1rem"></span>
            <p-button
                label="Speichern"
                icon="pi pi-check-circle"
                iconPos="right"
                [disabled]="checkSaveButton()"
                (onClick)="save()"
            ></p-button>
        </div>
    }
</div>
