<div
    style="
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
    "
>
    @if (ongoingRequest == true) {
        <div class="loading-container">
            <p-progressSpinner></p-progressSpinner>
        </div>
    }
    <p-confirmDialog
        [style]="{
            'min-width': '25rem',
            width: '50%'
        }"
    ></p-confirmDialog>
    <h2 style="text-align: center">
        Erfassung vom
        {{
            toDate(currentTimeset?.start).toLocaleDateString(['de-de'], {
                month: 'long',
                day: 'numeric'
            })
        }}
    </h2>
    <div>
        <app-event-view [eventViews]="eventViews"></app-event-view>
    </div>
    <div style="display: flex; flex-grow: 1"></div>
    <form
        [formGroup]="todayForm"
        (ngSubmit)="onSubmit()"
        style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 0.5rem;
        "
    >
        <p-calendar
            formControlName="timeControl"
            [timeOnly]="true"
            [inline]="true"
            hourFormat="24"
            [inputStyle]="{'text-align': 'center'}"
            [panelStyle]="{
                border: 'none',
                'max-width': '15rem',
                'min-width': '15rem'
            }"
            pTooltip="Wähle einen Zeitpunkt aus."
        ></p-calendar>
        <div style="margin-top: 0.5rem">
            @if (eventTypes.length > 0) {
                <p-splitButton
                    styleClass="p-button-outlined"
                    [label]="defaultEventType"
                    [model]="eventTypes"
                    (onClick)="performEvent()"
                    [disabled]="validationErrors.length > 0 || ongoingRequest"
                    [pTooltip]="
                        'Klicken Sie hier, um einen neuen Zeitstempel hinzuzufügen'
                    "
                >
                    (onClick)="performEvent()" [disabled]=" !timeIsNotFuture ||
                    ongoingRequest || eventIsShorterFiveMinutes " >
                </p-splitButton>
            }
            @if (eventTypes.length === 0) {
                <button
                    pButton
                    [label]="defaultEventType"
                    type="submit"
                    [disabled]="ongoingRequest || validationErrors.length > 0"
                    style="width: 12rem"
                    pTooltip="Füge einen neuen Zeitstempel hinzu."
                ></button>
            }
        </div>
        @for (error of validationErrors; track error) {
            @if (error) {
                <p style="color: var(--orange-500)">
                    {{ error }}
                </p>
            }
        }
    </form>
    <div class="comment-container">
        @for (
            event of currentTimeset?.events ?? [];
            track event;
            let index = $index
        ) {
            <app-comment-bar
                [event]="event"
                [eventIndex]="index"
                (commentChanged)="updateEventComment(index, $event)"
            ></app-comment-bar>
        }
    </div>
    <p-dialog
        header="Arbeit beenden?"
        [modal]="true"
        [draggable]="false"
        [visible]="dialogState !== undefined"
        (visibleChange)="dialogState = undefined"
    >
        <div
            class="flex flex-column align-items-center p-5 surface-overlay border-round"
        >
            <p>Möchtest du die Arbeit wirklich beenden?</p>
            @if (dialogState?.commentControl) {
                <p>
                    Das letzte Zeitfenster beinhaltet kein Kommentar. Dieses
                    kannst du hier optional ergänzen
                </p>
                <app-comment-bar
                    style="width: 100%"
                    [event]="dialogState!.event"
                    [eventIndex]="dialogState!.eventIndex"
                    [commentOnly]="true"
                    (modeChanged)="
                        dialogState!.buttonsEnabled = $event === 'read'
                    "
                ></app-comment-bar>
            }
            <div class="flex align-items-center gap-2 mt-4">
                <button
                    pButton
                    label="Abbrechen"
                    (click)="dialogState = undefined"
                ></button>
                <button
                    pButton
                    [disabled]="!dialogState?.buttonsEnabled"
                    label="Ja, Arbeit beenden"
                    (click)="this.uploadTimeEvent('STOP_WORK')"
                ></button>
            </div>
        </div>
    </p-dialog>
</div>
