<div>
    <div class="timeline-container">
        @for (event of eventViews; track event) {
            <div
                class="event-block p-fluid"
                [ngStyle]="{
                    width: event.weight + '%',
                    'background-color': getEventColor(event)
                }"
            >
                @if (event.weight > 5) {
                    @if (event.weight > 16) {
                        <span>{{ event.start | date: 'HH:mm' }}</span>
                        <span>{{ event.end | date: 'HH:mm' }}</span>
                    } @else {
                        <span>
                            {{
                                event.duration > 0
                                    ? (event.duration / 60 | number: '1.0-1')
                                    : '-'
                            }}h
                        </span>
                    }
                }
            </div>
        }
    </div>
    <div
        class="card flex flex-wrap gap-3 justify-content-left"
        style="display: flex; flex-direction: row; margin-bottom: 2rem"
    >
        <div class="legend">
            <div
                class="color-box"
                [ngStyle]="{'background-color': getBreakColor()}"
            >
                {{ sumBreak > 0 ? formatSum(sumBreak) : '' }}
            </div>
            <div class="description">Pause</div>
        </div>
        <div class="legend">
            <div
                class="color-box"
                [ngStyle]="{'background-color': getWorkColor()}"
            >
                {{ sumWork > 0 ? formatSum(sumWork) : '' }}
            </div>
            <div class="description">Arbeitszeit</div>
        </div>
        @if (gt6hEvent) {
            <div class="legend">
                <div
                    class="color-box"
                    [ngStyle]="{'background-color': getWarningColor()}"
                ></div>
                <div class="description">
                    Arbeitszeit von 6 Stunden überschritten
                </div>
            </div>
        }
    </div>
</div>
