import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {HeaderbarComponent} from './components/headerbar/headerbar.component';
import {LoginScreenComponent} from './components/auth/login-screen/login-screen.component';
import {TimesetScreenComponent} from './components/timeset-screen/timeset-screen.component';
import {TimesetOverviewComponent} from './components/timeset-overview/timeset-overview.component';
import {TimesetEditComponent} from './components/timeset-edit/timeset-edit.component';
import {ImpressumComponent} from './components/impressum/impressum.component';
import {MenubarModule} from '@timebox/custom-primeng/menubar';
import {RouterModule} from '@angular/router';
import {routes} from './app.routes';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalendarModule} from '@timebox/custom-primeng/calendar';
import {AuthComponent} from './components/auth/auth.component';
import {PasswordResetComponent} from './components/auth/password-reset/password-reset.component';
import {ToastModule} from '@timebox/custom-primeng/toast';
import {DividerModule} from '@timebox/custom-primeng/divider';
import {PasswordModule} from '@timebox/custom-primeng/password';
import {InputTextModule} from '@timebox/custom-primeng/inputtext';
import {ButtonModule} from '@timebox/custom-primeng/button';
import {SplitButtonModule} from '@timebox/custom-primeng/splitbutton';
import {DynamicDialogModule} from '@timebox/custom-primeng/dynamicdialog';
import {StepsModule} from '@timebox/custom-primeng/steps';
import {TableModule} from '@timebox/custom-primeng/table';
import {CheckboxModule} from '@timebox/custom-primeng/checkbox';
import {RadioButtonModule} from '@timebox/custom-primeng/radiobutton';
import {ToggleButtonModule} from '@timebox/custom-primeng/togglebutton';
import {ThemeSwitchComponent} from './components/theme-switch/theme-switch.component';
import {ProgressSpinnerModule} from '@timebox/custom-primeng/progressspinner';
import {ChallengeComponent} from './components/auth/challenge/challenge.component';
import {ConfirmDialogModule} from '@timebox/custom-primeng/confirmdialog';
import {ConfirmPopupModule} from '@timebox/custom-primeng/confirmpopup';
import {MessageModule} from '@timebox/custom-primeng/message';
import {InfoCircleIcon} from '@timebox/custom-primeng/icons/infocircle';
import {MessageService} from '@timebox/custom-primeng/api';
import {PaginatorModule} from '@timebox/custom-primeng/paginator';
import {DialogModule} from '@timebox/custom-primeng/dialog';
import {InputTextareaModule} from '@timebox/custom-primeng/inputtextarea';
import {CommentBarComponent} from './components/timeset-screen/comment-bar/comment-bar.component';
import {SelfServiceComponent} from './components/self-service/self-service.component';
import {EditorModule} from '@timebox/custom-primeng/editor';
import {RippleModule} from '@timebox/custom-primeng/ripple';
import {DataViewModule} from '@timebox/custom-primeng/dataview';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {EventViewComponent} from './components/event-view/event-view.component';
import {EventRowComponent} from './components/timeset-edit/event-row/event-row.component';
import {OverlayPanelModule} from '@timebox/custom-primeng/overlaypanel';
import {ScrollPanelModule} from '@timebox/custom-primeng/scrollpanel';
import {FieldsetModule} from '@timebox/custom-primeng/fieldset';
import {PanelModule} from '@timebox/custom-primeng/panel';
import {MessagesModule} from '@timebox/custom-primeng/messages';
import {SkeletonModule} from '@timebox/custom-primeng/skeleton';
registerLocaleData(localeDe);

@NgModule({
    declarations: [
        AppComponent,
        HeaderbarComponent,
        LoginScreenComponent,
        TimesetScreenComponent,
        TimesetOverviewComponent,
        TimesetEditComponent,
        EventRowComponent,
        AuthComponent,
        ImpressumComponent,
        PasswordResetComponent,
        ThemeSwitchComponent,
        ChallengeComponent,
        CommentBarComponent,
        SelfServiceComponent,
    ],
    imports: [
        RouterModule.forRoot(routes),
        ConfirmDialogModule,
        BrowserAnimationsModule,
        BrowserModule,
        InputTextareaModule,
        HttpClientModule,
        SkeletonModule,
        MenubarModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        OverlayPanelModule,
        SplitButtonModule,
        ToastModule,
        DividerModule,
        PasswordModule,
        InputTextModule,
        ButtonModule,
        DynamicDialogModule,
        DialogModule,
        StepsModule,
        TableModule,
        RadioButtonModule,
        CheckboxModule,
        ToggleButtonModule,
        ProgressSpinnerModule,
        ConfirmPopupModule,
        MessageModule,
        InfoCircleIcon,
        PaginatorModule,
        EditorModule,
        RippleModule,
        DataViewModule,
        EventViewComponent,
        ScrollPanelModule,
        FieldsetModule,
        PanelModule,
        MessagesModule,
    ],
    providers: [MessageService],
    bootstrap: [AppComponent],
})
export class AppModule {}
