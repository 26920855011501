import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const api = inject(AuthService);
    const knownState = api.authenticationSignal;
    const oldParams = {...route.queryParams};
    if (knownState !== undefined) {
        if (!knownState) {
            oldParams['retUrl'] = route.url;
            router.navigate(['login'], {queryParams: oldParams});
        }
        return knownState;
    } else {
        return new Observable<boolean>(observer => {
            const sub = api.authenticationSignalAsync.subscribe(isAuth => {
                if (!isAuth) {
                    oldParams['retUrl'] = route.url;
                    router.navigate(['login'], {queryParams: oldParams});
                    observer.next(false);
                } else {
                    observer.next(true);
                }
                observer.complete();
                sub.unsubscribe();
            });
        });
    }
};
