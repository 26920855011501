<div>
    <div style="margin-left: 1rem">
        <h2>Meine Zeiten</h2>
    </div>
    <div class="content-flex">
        <div>
            <div class="calender-card">
                <p-calendar
                    #datePickerCalendar
                    [inline]="true"
                    [(ngModel)]="currentDate"
                    [disabled]="editMode"
                    [maxDate]="maxDate"
                    (ngModelChange)="changeSelectedDate()"
                    (onMonthChange)="changeMonth($event)"
                    [showLoadingAnimation]="loadingMonth"
                    [style]="{width: '22rem', border: 'none'}"
                >
                <ng-template pTemplate="date" let-date>
                    <span [ngStyle]="getDateStyle(date)">{{ date.day }}</span>
                    <!-- <span [ngStyle]="{textDecoration: (date.day < 21 && date.day > 10) ? 'line-through' : 'inherit'}">{{date.day}}</span> -->
                </ng-template>


                </p-calendar>
                <button
                pButton
                label="Monatsbericht versenden"
                (click)="sendMonthlyReport()"
                *ngIf="getIsManualExportAllowed()"
                [disabled]="requestMonth === true"
                ></button>
            </div>
            
            <div class="work-time-container">
                <div class="work-time-header">
                    <span>Arbeitszeiten:</span>
                    <p-button
                        (onClick)="addTimeset()"
                        label="Neu"
                        icon="pi pi-plus"
                        [disabled]="editMode || !isEditable()"
                    ></p-button>
                </div>
                <p-confirmPopup></p-confirmPopup>
                @for (item of dateTimesets; track $index) {
                    <div
                        class="work-time-entry"
                        (click)="selectTimeset($index, 'html')"
                        [ngStyle]="{
                            'border-color':
                                selectedTimesetIndex === $index
                                    ? 'var(--text-color-secondary)'
                                    : '#cccccc'
                        }"
                    >
                        <span>{{ getTimesetString(item) }}</span>
                        @if (isEditable(item)) {
                            @if (selectedTimesetIndex === $index && editMode) {
                                <div>
                                    <button
                                        pButton
                                        pRipple
                                        icon="pi pi-check"
                                        class="p-button-rounded p-button-text p-button-success"
                                        (click)="handleSaveTrigger()"
                                    ></button>
                                    <button
                                        pButton
                                        pRipple
                                        icon="pi pi-times"
                                        style="color: var(--orange-500)"
                                        class="p-button-rounded p-button-text"
                                        (click)="handleCancelTrigger($event)"
                                    ></button>
                                </div>
                            } @else {
                                <div>
                                    <button
                                        pButton
                                        pRipple
                                        icon="pi pi-pencil"
                                        style="color: var(--work-color)"
                                        class="p-button-rounded p-button-text"
                                        (click)="editTimeset(item.timesetId)"
                                    ></button>
                                    <button
                                        pButton
                                        pRipple
                                        icon="pi pi-trash"
                                        style="color: var(--orange-500)"
                                        class="p-button-rounded p-button-text"
                                        (click)="
                                            requestTimesetDeletion(item, $event)
                                        "
                                    ></button>
                                </div>
                            }
                        } @else {
                            <button
                                pButton
                                pRipple
                                icon="pi pi-lock"
                                class="p-button-rounded p-button-text"
                                [disabled]="true"
                            ></button>
                        }
                    </div>
                }
            </div>
        </div>
        <div style="width: 2rem"></div>
        @if (currentDate && !loadingMonth) {
            @if (loadingSelection) {
                <div style="width: 100%; margin: 10rem; text-align: center">
                    <p-progressSpinner></p-progressSpinner>
                </div>
            } @else {
                @if (selectedTimeset) {
                    <app-timeset-edit
                        #timesetEdit
                        [(timeset)]="selectedTimeset"
                        (timesetChange)="rerenderDateTimesets()"
                        [editMode]="editMode"
                        (cancelTrigger)="handleCancelTrigger($event)"
                        (saveTrigger)="handleSaveTrigger()"
                    ></app-timeset-edit>
                } @else {
                    <p>Es wurden noch keine Zeiten erfasst.</p>
                }
            }
        }
    </div>
</div>
