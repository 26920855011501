<div style="display: flex; width: 100%; justify-content: center">
    <div class="card">
        <div class="menubar-container">
            <p-menubar [model]="menuItems">
                <ng-template pTemplate="start">
                    <div class="bg-container">
                        <img
                            [src]="headerUrl"
                            class="bg-full"
                            alt="Header Hintergrundbild"
                        />
                    </div>
                    <div style="display: flex; flex-direction: row">
                        <a routerLink="/" class="logo-wrapper">
                            <img
                                [src]="logoUrl"
                                height="40"
                                alt="Unternehmenslogo"
                            />
                            <h1
                                id="title"
                                style="margin: 0; padding-left: 1rem"
                            >
                                {{ title }}
                            </h1>
                        </a>
                    </div>
                </ng-template>
            </p-menubar>
        </div>
    </div>
</div>
