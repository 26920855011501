import {Component, OnInit} from '@angular/core';
import {PrivacyPolicyService} from '../../services/privacy-policy.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-impressum',
    templateUrl: './impressum.component.html',
    styleUrls: ['./impressum.component.css'],
})
export class ImpressumComponent implements OnInit {
    constructor(
        private privacyPolicyService: PrivacyPolicyService,
        private location: Location
    ) {}
    privacyPolicy: string | undefined;

    ngOnInit() {
        this.privacyPolicyService.getPrivacyPolicy().subscribe({
            next: pol => {
                this.privacyPolicy = pol;
            },
            error: err => {
                console.error(err);
            },
        });
    }

    back() {
        this.location.back();
    }
}
