<div class="card">
    <form [formGroup]="selfServiceForm" (ngSubmit)="submitSelfService()">
        <p>
            <label for="email">E-Mail Adresse</label>
            <input
                id="email"
                type="email"
                pInputText
                formControlName="userEmail"
                [email]="true"
                [minLength]="3"
            />
        </p>
        <p>
            <label for="subject">Betreff</label>
            <input
                id="subject"
                type="text"
                pInputText
                formControlName="subject"
                [maxLength]="100"
            />
        </p>
        <p>
            <label for="description">Beschreibung</label>
            <textarea
                rows="6"
                pInputTextarea
                id="description"
                formControlName="description"
                [maxLength]="1000"
            ></textarea>
        </p>
        <p>
            <button
                pButton
                type="submit"
                pRipple
                icon="pi pi-send"
                label="Abschicken"
                [disabled]="!selfServiceForm['valid'] || ongoingRequest"
            ></button>
        </p>
    </form>
</div>
