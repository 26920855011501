export function toTimestamp(date: Date | null) {
    return Math.floor((date?.getTime() ?? Date.now()) / 1000);
}

export function toDate(timestamp: number) {
    return new Date(timestamp * 1000);
}

export function dateToSimpleTime(date: Date) {
    return date.toLocaleTimeString(['de-de'], {
        hour: '2-digit',
        minute: '2-digit',
    });
}

export function timestampToSimpleTime(timestamp: number) {
    return dateToSimpleTime(toDate(timestamp));
}
