import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import {TimesetEventType} from 'src/app/models/EventType';
import {TimesetEvent} from 'src/app/models/api-models/Event';
import {ThemesService} from 'src/app/services/themes.service';
import {toDate, toTimestamp} from 'src/utils/converter';
import {colors} from 'src/utils/tools';
import {TimesetEditComponent} from '../timeset-edit.component';
@Component({
    selector: 'app-event-row',
    templateUrl: './event-row.component.html',
    styleUrl: './event-row.component.scss',
})
export class EventRowComponent implements OnInit, DoCheck {
    @Input() event!: TimesetEvent;
    @Input() editMode!: boolean;
    @Input() boundary!: {min: Date; max: Date};
    @Input() isFirstEvent: boolean = false;
    @Input() isLastEvent: boolean = false;
    @Input() minimumEventSecs!: number;
    @Output() eventChange = new EventEmitter<TimesetEvent>();
    @Output() hasError = new EventEmitter<boolean>();

    themeService = inject(ThemesService);

    commentState: 'read' | 'edit' = 'read';
    commentFeatureEnabled: boolean;
    maxCommentLength = 256;

    placeholder!: string;
    color!: string;

    dates = {
        start: new Date(),
        end: new Date(),
    };

    endButtons = {
        allowHourUp: true,
        allowMinuteUp: true,
        allowHourDown: true,
        allowMinuteDown: true,
    };

    startButtons = {
        allowHourUp: true,
        allowMinuteUp: true,
        allowHourDown: true,
        allowMinuteDown: true,
    };

    _maxError: string | undefined;
    _minError: string | undefined;

    get maxError() {
        return this._maxError;
    }
    set maxError(v: string | undefined) {
        this._maxError = v;
        this.checkError();
    }

    get minError() {
        return this._minError;
    }
    set minError(v: string | undefined) {
        this._minError = v;
        this.checkError();
    }

    checkError() {
        const hasError =
            this.maxError !== undefined || this._minError !== undefined;
        this.hasError.next(hasError);
        return hasError;
    }

    constructor() {
        this.commentFeatureEnabled = this.themeService.config.isCommentsAllowed;
        this.maxCommentLength = this.themeService.config.maxCommentLength;
        if (this.maxCommentLength > 256) {
            this.maxCommentLength = 256;
        }
    }

    ngDoCheck(): void {
        this.updateDates();
    }

    ngOnInit(): void {
        this.updateDates();
        if (this.event.type === TimesetEventType.BREAK) {
            this.color = this.getBreakDuration()
                ? colors.warnColor
                : colors.breakColor;
            this.placeholder = 'Pause';
            this.commentFeatureEnabled = false;
        } else {
            this.color = this.timesetToLong(this.getTimesetDuration())
                ? colors.warnColor
                : colors.workColor;
            this.placeholder = this.commentFeatureEnabled
                ? 'Kein Kommentar erfasst'
                : 'Arbeit';
        }
    }

    getBreakDuration() {
        const duration = this.getTimesetDuration();
        return duration > 6 ? true : false;
    }

    getTimesetDuration() {
        const start = this.event.start / 60 / 60;
        const end = this.event.end! / 60 / 60;
        return end - start;
    }

    timesetToLong(diff: number) {
        return diff > 6 ? true : false;
    }

    changeEndDate(diff: number) {
        // add one day to date object
        this.dates.end.setDate(this.dates.end.getDate() + diff);
        this.updateEnd(this.dates.end);
        if (this.event.type === TimesetEventType.BREAK) {
            this.color = this.getBreakDuration()
                ? colors.warnColor
                : colors.breakColor;
        } else {
            this.color = this.timesetToLong(this.getTimesetDuration())
                ? colors.warnColor
                : colors.workColor;
        }
    }
    changeStartDate(diff: number) {
        // add one day to date object
        this.dates.start.setDate(this.dates.start.getDate() + diff);
        this.updateStart(this.dates.start);
        if (this.event.type === TimesetEventType.BREAK) {
            this.color = this.getBreakDuration()
                ? colors.warnColor
                : colors.breakColor;
        } else {
            this.color = this.timesetToLong(this.getTimesetDuration())
                ? colors.warnColor
                : colors.workColor;
        }
    }

    updateDates() {
        const start = toDate(this.event.start);
        const end = toDate(this.event.end!);
        if (this.dates.start.getTime() !== start.getTime()) {
            this.dates.start = start;
        }
        if (this.dates.end.getTime() !== end.getTime()) {
            this.dates.end = end;
        }
        this.validateStartPicker();
        this.validateEndPicker();
        if (this.event.type === TimesetEventType.BREAK) {
            this.color = this.getBreakDuration()
                ? colors.warnColor
                : colors.breakColor;
        } else {
            this.color = this.timesetToLong(this.getTimesetDuration())
                ? colors.warnColor
                : colors.workColor;
        }
    }

    updateStart(event: Date) {
        const diff = this.calcStartDiff();

        if (diff.max < 0 || diff.min < 0) {
            if (diff.max < 0) {
                this.minError = `Aktueller Zeitraum würde die Mindestdauer von ${this.minimumEventSecs / 60} Minuten unterschreiten`;
            } else {
                if (this.isFirstEvent) {
                    this.minError = `Start liegt zu weit in der Vergangenheit (mehr als ${TimesetEditComponent.MAX_BOUNDARY_HOURS} Stunden)`;
                } else {
                    this.minError = `Vorheriger Zeitraum würde die Mindestdauer von ${this.minimumEventSecs / 60} Minuten unterschreiten`;
                }
            }
            this.event.start = toTimestamp(event);
            if (this.event.type === TimesetEventType.BREAK) {
                this.color = this.getBreakDuration()
                    ? colors.warnColor
                    : colors.breakColor;
            } else {
                this.color = this.timesetToLong(this.getTimesetDuration())
                    ? colors.warnColor
                    : colors.workColor;
            }
        } else if (!isNaN(event.getTime())) {
            this.minError = undefined;
            this.event.start = toTimestamp(event);
            this.update();
            this.validateStartPicker();
            if (this.event.type === TimesetEventType.BREAK) {
                this.color = this.getBreakDuration()
                    ? colors.warnColor
                    : colors.breakColor;
            } else {
                this.color = this.timesetToLong(this.getTimesetDuration())
                    ? colors.warnColor
                    : colors.workColor;
            }
        }
    }

    updateEnd(event: Date) {
        const diff = this.calcEndDiff();

        if (diff.min < 0 || diff.max < 0) {
            if (diff.min < 0) {
                this.maxError = `Aktueller Zeitraum würde die Mindestdauer von ${this.minimumEventSecs / 60} Minuten unterschreiten`;
            } else {
                if (this.isLastEvent) {
                    this.maxError = `Ende liegt zu weit in der Zukunft (mehr als ${TimesetEditComponent.MAX_BOUNDARY_HOURS} Stunden)`;
                } else {
                    this.maxError = `Nächster Zeitraum würde die Mindestdauer von ${this.minimumEventSecs / 60} Minuten unterschreiten`;
                }
            }
            this.event.end = toTimestamp(event);
            if (this.event.type === TimesetEventType.BREAK) {
                this.color = this.getBreakDuration()
                    ? colors.warnColor
                    : colors.breakColor;
            } else {
                this.color = this.timesetToLong(this.getTimesetDuration())
                    ? colors.warnColor
                    : colors.workColor;
            }
        } else if (!isNaN(event.getTime())) {
            this.maxError = undefined;
            this.event.end = toTimestamp(event);
            this.update();
            this.validateEndPicker();
            if (this.event.type === TimesetEventType.BREAK) {
                this.color = this.getBreakDuration()
                    ? colors.warnColor
                    : colors.breakColor;
            } else {
                this.color = this.timesetToLong(this.getTimesetDuration())
                    ? colors.warnColor
                    : colors.workColor;
            }
        }
    }

    calcStartDiff() {
        return {
            max:
                toTimestamp(this.dates.end) -
                this.minimumEventSecs -
                toTimestamp(this.dates.start),
            min: toTimestamp(this.dates.start) - toTimestamp(this.boundary.min),
        };
    }

    calcEndDiff() {
        return {
            max: toTimestamp(this.boundary.max) - toTimestamp(this.dates.end),
            min:
                toTimestamp(this.dates.end) -
                (toTimestamp(this.dates.start) + this.minimumEventSecs),
        };
    }

    getDiffHM(diff: {max: number; min: number}) {
        const maxSum = diff.max / 60;
        const minSum = diff.min / 60;
        return {
            maxH: Math.floor(maxSum / 60),
            maxM: Math.floor(maxSum % 60),
            minH: Math.floor(minSum / 60),
            minM: Math.floor(minSum % 60),
        };
    }

    validateEndPicker() {
        const diff = this.calcEndDiff();
        const hm = this.getDiffHM(diff);

        this.endButtons.allowMinuteUp = hm.maxH > 0 || hm.maxM > 0;
        this.endButtons.allowHourUp =
            hm.maxH > 0 && this.dates.end.getHours() < 23;
        this.endButtons.allowHourDown = hm.minH > 0;
        this.endButtons.allowMinuteDown = hm.minH > 0 || hm.minM > 0;
    }

    validateStartPicker() {
        const diff = this.calcStartDiff();
        const hm = this.getDiffHM(diff);

        this.startButtons.allowMinuteUp = hm.maxH > 0 || hm.maxM > 0;
        this.startButtons.allowHourUp =
            hm.maxH > 0 && this.dates.start.getHours() < 23;
        this.startButtons.allowHourDown = hm.minH > 0;
        this.startButtons.allowMinuteDown = hm.minH > 0 || hm.minM > 0;
    }

    toDate(timestamp: number) {
        return toDate(timestamp);
    }

    update() {
        if (!this.checkError()) {
            this.eventChange.emit(this.event);
        }
    }

    startEditing() {
        if (this.commentState === 'edit' || !this.commentFeatureEnabled) return;
        this.commentState = 'edit';
        return true;
    }

    endEditing() {
        if (this.commentState === 'read' || !this.commentFeatureEnabled) return;
        this.commentState = 'read';
        const trimmedText = this.event.comment?.trim();
        this.event.comment = trimmedText ? trimmedText : undefined;
        this.update();
    }

    detectEnterKey(event: KeyboardEvent) {
        if (['Enter', 'Escape'].includes(event.key)) {
            event.preventDefault();
            this.endEditing();
        }
    }
}
