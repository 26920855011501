import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {lastValueFrom, Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class PrivacyPolicyService {
    private static ENDPOINT = environment.apiEndpoint;

    public static readonly DEFAULT_POLICY = `\n\nAllgemeine Datenschutzbestimmungen:
          Zur Einhaltung des Datenschutzes werde deine Daten... TODO!
    `;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    public getPrivacyPolicy(): Observable<string> {
        const request = new HttpRequest(
            'GET',
            `${PrivacyPolicyService.ENDPOINT}/config/privacypolicy`,
            {
                responseType: 'text',
            }
        );

        return new Observable<string>(subscriber => {
            if (this.authService.authenticationSignal) {
                this.authService
                    .signHttpRequest(request)
                    .then(req =>
                        lastValueFrom(
                            this.http.request(req) as Observable<
                                HttpResponse<string>
                            >
                        )
                    )
                    .then(policy =>
                        subscriber.next(
                            policy.body ??
                                'Von deiner Organisation wurde keine Datenschutzbestimmung angegeben.' +
                                    PrivacyPolicyService.DEFAULT_POLICY
                        )
                    )
                    .catch((err: HttpErrorResponse) => {
                        if (err.status === 404) {
                            subscriber.next(
                                'Von deiner Organisation wurde keine Datenschutzbestimmung angegeben.' +
                                    PrivacyPolicyService.DEFAULT_POLICY
                            );
                        } else {
                            console.error(err);
                            subscriber.next(
                                'Datenschutzbestimmungen konnten nicht geladen werden.' +
                                    PrivacyPolicyService.DEFAULT_POLICY
                            );
                        }
                    });
            } else {
                subscriber.next(
                    'Deine organisations-spezifischen Datenschutzbestimmungen sind erst nach der Anmeldung abrufbar.'
                );
            }
        });
    }
}
