import {Routes} from '@angular/router';
import {LoginScreenComponent} from './components/auth/login-screen/login-screen.component';
import {TimesetScreenComponent} from './components/timeset-screen/timeset-screen.component';
import {TimesetOverviewComponent} from './components/timeset-overview/timeset-overview.component';
import {PasswordResetComponent} from './components/auth/password-reset/password-reset.component';
import {ChallengeComponent} from './components/auth/challenge/challenge.component';
import {authGuard} from './auth/auth.guard';
import {ImpressumComponent} from './components/impressum/impressum.component';

/**
 * Sammlung der in der Applikation genutzten routen
 * https://angular.io/guide/router
 * @example { path: 'routename', component: ComponentSelector }
 */
export const routes: Routes = [
    /**
     * { path: 'example', component: ComponentSelector }
     */
    {path: '', redirectTo: 'today', pathMatch: 'full'},
    {path: 'login', component: LoginScreenComponent},
    {path: 'password-reset', component: PasswordResetComponent},
    {
        path: 'challenge',
        component: ChallengeComponent,
        title: 'Challenge',
    },
    {
        path: 'today',
        component: TimesetScreenComponent,
        canActivate: [authGuard],
        title: 'Heute',
    },
    {
        path: 'times',
        component: TimesetOverviewComponent,
        canActivate: [authGuard],
        title: 'Meine Zeiten',
    },
    {
        path: 'impressum',
        component: ImpressumComponent,
        title: 'Impressum & Datenschutz',
    },
];
