import {Stage} from 'src/utils/types';

export const environment = {
    stage: Stage.dev,
    buildtime: 1709021666447,
    apiEndpoint:
        'https://lhjybiohu8.execute-api.eu-central-1.amazonaws.com/dev',
    cognito: {
        userPoolId: 'eu-central-1_qWKSDVgRm',
        clientId: '7jm2c7m20nqqm7bka6r6gvtg49',
    },
};
