import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, inject} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {Observable, ReplaySubject, lastValueFrom} from 'rxjs';
import {UXModel} from '@timebox/timebox-types';
interface DesignConfig {
    accentColor: string;
    background: string;
    logo: string;
}
interface UXConfig {
    isCommentsAllowed: boolean;
    maxCommentLength: number;
    maximumRelativePastStampTimeInMinutes: number;
    maximumRelativeFutureStampTimeInMinutes: number;
    minimumEventLengthInMinutes: number;
    recordableDaysInPast: number;
    isManualExportAllowed: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ThemesService {
    private authService = inject(AuthService);
    private static THEME = 'timebox-theme';
    public theme = ThemesService.THEME;

    private defaultConfig: UXConfig = {
        isCommentsAllowed: false,
        maxCommentLength: 0,
        maximumRelativePastStampTimeInMinutes: 480,
        maximumRelativeFutureStampTimeInMinutes: 480,
        minimumEventLengthInMinutes: 5,
        recordableDaysInPast: 7,
        isManualExportAllowed: false,
    };

    private lastCustomerId: string | undefined;
    private _config: UXConfig | undefined;
    public get config(): UXConfig {
        return this._config ?? this.defaultConfig;
    }
    private set config(value: UXConfig) {
        this._config = value;
    }

    private _design: DesignConfig | undefined;
    public get design(): DesignConfig | null {
        return this._design ?? null;
    }
    private set design(value: DesignConfig | undefined) {
        this._design = value;
        this.designChanged.next(value);
    }

    public designChanged = new ReplaySubject<DesignConfig | undefined>();
    public designLoading = new ReplaySubject<boolean>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cookie: CookieService,
        private http: HttpClient
    ) {
        this.initValues();
        this.authService.authenticationSignalAsync.subscribe(auth => {
            if (auth && this.authService.user?.customerId) {
                if (this.lastCustomerId === this.authService.user?.customerId) {
                    return;
                }
                this.designLoading.next(true);
                console.debug('refreshing uxmodel');
                const request = new HttpRequest(
                    'GET',
                    environment.apiEndpoint + '/config/uxmodel'
                );
                this.authService
                    .signHttpRequest(request, {omitSignal: true})
                    .then(req =>
                        lastValueFrom(
                            this.http.request(req) as Observable<
                                HttpResponse<UXModel>
                            >
                        )
                    )
                    .then((data: HttpResponse<UXModel>) => {
                        this.lastCustomerId = this.authService.user?.customerId;
                        this.updateFromApi(data.body);
                    });
            }
        });
    }

    private initValues() {
        const lastCustomer = this.cookie.get('timebox-ux-customer');
        const lastConfig = this.cookie.get('timebox-ux-config');
        const lastDesign = this.cookie.get('timebox-ux-design');
        if (lastCustomer) {
            this.lastCustomerId = lastCustomer;
        }
        if (lastConfig) {
            this.config = JSON.parse(lastConfig);
        }
        if (lastDesign) {
            this.design = JSON.parse(lastDesign);
        }
    }

    private updateFromApi(model: UXModel | null) {
        if (this.lastCustomerId) {
            this.cookie.set('timebox-ux-customer', this.lastCustomerId, 0);
        }
        if (model) {
            this.config = {...model};
            this.cookie.set(
                'timebox-ux-config',
                JSON.stringify(this.config),
                0
            );
            if (model?.design) {
                this.design = model.design;
                this.cookie.set(
                    'timebox-ux-design',
                    JSON.stringify(this.design),
                    364
                );
            } else {
                this.design = undefined;
                this.cookie.delete('timebox-ux-design');
            }
        }
        this.designLoading.next(false);
    }

    // toggleTheme(lightMode: boolean) {
    //     const themeLink: HTMLLinkElement = this.document.getElementById(
    //         'app-theme'
    //     ) as HTMLLinkElement;

    //     if (lightMode) {
    //         this.cookie.set(ThemesService.THEME, 'Dark.css' ?? '', {});
    //         themeLink.href = 'Dark.css';
    //     } else {
    //         this.cookie.set(ThemesService.THEME, 'Light.css' ?? '', {});
    //         themeLink.href = 'Light.css';
    //     }
    // }
}
