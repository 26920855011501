<app-auth [title]="'Timebox Anmeldung'" [showLoading]="ongoingRequest">
    <form [formGroup]="loginForm" (ngSubmit)="login()" #form="ngForm">
        <input
            pInputText
            type="email"
            inputmode="email"
            formControlName="username"
            placeholder="{{ htmlArguments.input1 }}"
            autocomplete="username"
            pTooltip="Geben Sie Ihren Benutzernamen ein."
        />
        <input
            pInputText
            type="password"
            (keyup.enter)="form.ngSubmit.emit()"
            formControlName="password"
            placeholder="{{ htmlArguments.input2 }}"
            autocomplete="current-password"
            pTooltip="Geben Sie Ihr Passwort ein."
        />
        <p-checkbox
            [binary]="true"
            [style.margin-top]="'1rem'"
            inputId="binary"
            label="Angemeldet bleiben"
            formControlName="stayIn"
            pTooltip="Speichert Ihre Anmeldedaten für den nächsten Login!"
        ></p-checkbox>
    </form>
    <section class="centered-actions">
        <p
            class="text-link"
            routerLink="/password-reset"
            pTooltip="Klicken Sie hier, wenn Sie Ihr Passwort vergessen haben."
        >
            Passwort vergessen?
        </p>
        <button
            (click)="form.ngSubmit.emit()"
            pButton
            [disabled]="loginForm.invalid || ongoingRequest"
            label="Anmelden"
            class="p-button"
            pTooltip="Klicken Sie hier, um sich einzuloggen."
        ></button>
    </section>
</app-auth>
