import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import {
    DialogService,
    DynamicDialogRef,
} from '@timebox/custom-primeng/dynamicdialog';
import {ThemesService} from './services/themes.service';
import {PrimeNGConfig} from '@timebox/custom-primeng/api';
import {CookieHandler} from './utils/CookieHandler';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {Stage} from 'src/utils/types';
import pgkJson from '../../package.json';
import {SelfServiceComponent} from './components/self-service/self-service.component';
import {MsgService} from './services/msg-service.service';
import {AuthService} from './services/auth.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [DialogService],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Timebox';
    showDebug: false | 'stage' | 'version' | 'buildtime' = false;
    debugInfo: {stage: string; version: string; buildtime: string};
    showMenu: boolean = false;
    ref: DynamicDialogRef | undefined;

    openedPopup = 0;
    showPersonaldataLoading = false;

    private authService = inject(AuthService);
    // private customerId: string | null;

    nextDebug() {
        switch (this.showDebug) {
            case 'stage':
                this.showDebug = 'version';
                break;
            case 'version':
                this.showDebug = 'buildtime';
                break;
            case 'buildtime':
                this.showDebug = 'stage';
                break;
        }
    }

    constructor(
        public dialogService: DialogService,
        private themeService: ThemesService,
        private config: PrimeNGConfig,
        private cookieHandler: CookieHandler,
        private router: Router,
        private messageService: MsgService
    ) {
        const date = new Date(environment.buildtime);
        const dateString = `${date.getDate()}.${
            date.getMonth() + 1
        }.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        this.debugInfo = {
            stage: environment.stage,
            version: pgkJson.version,
            buildtime: dateString,
        };
        if (environment.stage !== Stage.prod) {
            this.showDebug = 'stage';
        }
        this.config.setTranslation({
            accept: 'Ja',
            reject: 'Nein',
            firstDayOfWeek: 1,
            dayNames: [
                'Sonntag',
                'Montag',
                'Dienstag',
                'Mittwoch',
                'Donnerstag',
                'Freitag',
                'Samstag',
            ],
            dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            monthNames: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
            ],
            monthNamesShort: [
                'Jan',
                'Feb',
                'Mär',
                'Apr',
                'Mai',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Okt',
                'Nov',
                'Dez',
            ],
            today: 'Heute',
            clear: 'Aufräumen',
            dateFormat: 'dd.mm.yy',
            weekHeader: 'Woche',
        });
        //set TCI
        //if authenticationSignal is === true show Nav Bar
        this.authService.checkAuthSession();
        this.authService.authenticationSignalAsync.subscribe(state => {
            this.showMenu = state;
            if (!state) {
                const route = router.routerState.snapshot.root;
                const oldParams = {...route.queryParams};
                oldParams['retUrl'] = route.url;
                router.navigate(['login'], {queryParams: oldParams});
            }
        });
        this.themeService.designLoading.subscribe(val => {
            const minTime = 1750 - (Date.now() - this.openedPopup);
            setTimeout(
                () => {
                    this.openedPopup = Date.now();
                    this.showPersonaldataLoading = val;
                },
                minTime > 0 ? minTime : 0
            );
        });
        this.themeService.designChanged.subscribe({
            next: design => {
                document.documentElement.style.setProperty(
                    '--text-color-secondary',
                    design?.accentColor ?? '#e3000f'
                );
            },
        });
    }
    // theme = this.cookieHandler.theme;

    ngOnInit() {
        // if (this.theme) {
        //     switch (this.theme) {
        //         case 'Dark.css':
        //             this.themeService.toggleTheme(true);
        //             break;
        //         default:
        //             this.themeService.toggleTheme(false);
        //             break;
        //     }
        // }
    }

    showSelfService() {
        this.ref = this.dialogService.open(SelfServiceComponent, {
            showHeader: true,
            header: 'Einen Fehler melden',
            style: {
                'min-width': '25rem',
                width: '70%',
            },
            contentStyle: {overflow: 'auto'},
            baseZIndex: 10000,
            maximizable: false,
            dismissableMask: true,
        });
    }

    ngOnDestroy() {
        if (this.ref) {
            this.ref.close();
        }
    }
}
