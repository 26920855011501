import {Component, Input} from '@angular/core';
import {ThemesService} from 'src/app/services/themes.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrl: './auth.component.scss',
})
export class AuthComponent {
    @Input() title!: string;
    @Input() subtitle: string | undefined;
    @Input() showLoading!: boolean;

    defaultBackgroundImage = '/assets/images/default_background.jpg';
    backgroundImage = this.defaultBackgroundImage;
    defaultLogoUrl = 'assets/images/default_logo.png';
    logoUrl = this.defaultLogoUrl;
    logoHeight = 100;

    constructor(themeService: ThemesService) {
        themeService.designChanged.subscribe({
            next: design => {
                this.backgroundImage =
                    design?.background ?? this.defaultBackgroundImage;
                this.logoUrl = design?.logo ?? this.defaultLogoUrl;
            },
        });
    }
}
