<div class="comment-container">
    <div
        class="comment-time"
        [ngStyle]="{
            'background-color': color,
            cursor: editMode ? 'pointer' : 'default',
            'text-decoration': editMode ? 'underline' : 'default',
            color: minError ? 'var(--orange-500)' : 'var(--text-color-white)'
        }"
        (click)="editMode ? startOp.toggle($event) : null"
    >
        {{
            toDate(event.start).toLocaleTimeString(['de-de'], {
                hour: '2-digit',
                minute: '2-digit'
            })
        }}
    </div>
    <p-overlayPanel #startOp>
        <ng-template pTemplate>
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 16rem;
                "
            >
                <div
                    style="
                        width: 100%;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        margin-top: 1rem;
                    "
                >
                    <i
                        class="pi pi-chevron-left month-change-button"
                        (click)="changeStartDate(-1)"
                    ></i>
                    <span>{{ dates.start.toLocaleDateString(['de-de']) }}</span>
                    <i
                        class="pi pi-chevron-right month-change-button"
                        (click)="changeStartDate(+1)"
                    ></i>
                </div>
                <hr />
                @if (minError) {
                    <span
                        style="
                            color: var(--orange-500);
                            font-size: 0.9rem;
                            text-align: center;
                        "
                        >{{ this.minError }}</span
                    >
                }
                <p-calendar
                    [inline]="true"
                    [timeOnly]="true"
                    [tabIndent]="3"
                    [panelStyle]="{border: 'none', width: '10rem'}"
                    [(ngModel)]="dates.start"
                    (ngModelChange)="updateStart($event)"
                    [disableHourUp]="!startButtons.allowHourUp"
                    [disableHourDown]="!startButtons.allowHourDown"
                    [disableMinuteUp]="!startButtons.allowMinuteUp"
                    [disableMinuteDown]="!startButtons.allowMinuteDown"
                ></p-calendar>
            </div>
        </ng-template>
    </p-overlayPanel>
    <div class="comment-content">
        <input
            type="text"
            #textAreaBox
            rows="1"
            class="comment-content"
            inputmode="text"
            [maxlength]="maxCommentLength"
            pInputText
            [placeholder]="placeholder"
            [readonly]="commentState !== 'edit'"
            (click)="commentFeatureEnabled && editMode ? startEditing() : null"
            (blur)="commentFeatureEnabled && editMode ? endEditing() : null"
            (touchstart)="commentFeatureEnabled && editMode ? startEditing() : null"
            [(ngModel)]="event.comment"
            (keydown)="detectEnterKey($event)"
            [ngStyle]="{
                cursor:
                    commentFeatureEnabled && editMode ? 'pointer' : 'default'
            }"
        >
        @if (commentState === 'edit') {
            <span class="char-counter"
                >{{ event.comment?.length ?? 0 }}/{{ maxCommentLength }}</span
            >
        }
    </div>
    <p-overlayPanel #endOp>
        <ng-template pTemplate pFocusTrap>
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 16rem;
                "
            >
                <div
                    style="
                        width: 100%;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        margin-top: 1rem;
                    "
                >
                    <i
                        class="pi pi-chevron-left month-change-button"
                        (click)="changeEndDate(-1)"
                    ></i>
                    <span>{{ dates.end.toLocaleDateString(['de-de']) }}</span>
                    <i
                        class="pi pi-chevron-right month-change-button"
                        (click)="changeEndDate(+1)"
                    ></i>
                </div>
                <hr />
                @if (maxError) {
                    <span
                        style="
                            color: var(--orange-500);
                            font-size: 0.9rem;
                            text-align: center;
                        "
                        >{{ this.maxError }}</span
                    >
                }
                <p-calendar
                    [inline]="true"
                    [timeOnly]="true"
                    [tabIndent]="3"
                    [panelStyle]="{border: 'none', width: '10rem'}"
                    [(ngModel)]="dates.end"
                    (ngModelChange)="updateEnd($event)"
                    [disableHourUp]="!endButtons.allowHourUp"
                    [disableHourDown]="!endButtons.allowHourDown"
                    [disableMinuteUp]="!endButtons.allowMinuteUp"
                    [disableMinuteDown]="!endButtons.allowMinuteDown"
                ></p-calendar>
            </div>
        </ng-template>
    </p-overlayPanel>
    <div
        class="comment-time"
        [ngStyle]="{
            'background-color': color,
            cursor: editMode ? 'pointer' : 'default',
            'text-decoration': editMode ? 'underline' : 'default',
            color: maxError ? 'var(--orange-500)' : 'var(--text-color-white)'
        }"
        (click)="editMode ? endOp.toggle($event) : null"
    >
        {{
            toDate(event.end ?? 0).toLocaleTimeString(['de-de'], {
                hour: '2-digit',
                minute: '2-digit'
            })
        }}
    </div>
</div>
