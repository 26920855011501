import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MessageService, Message} from '@timebox/custom-primeng/api';
import {ERROR_MESSAGES} from './toast-messages';
@Injectable({
    providedIn: 'root',
})
export class MsgService {
    public onCloseCallbacks: Record<string, () => {}> = {};
    constructor(private pngMsgService: MessageService) {
        this.pngMsgService.messageObserver.subscribe(data => {
            if (Array.isArray(data)) {
                return;
            }
            if (data.id in this.onCloseCallbacks) {
                this.onCloseCallbacks[data.id]();
            }
        });
    }

    public add(message: Message) {
        this.pngMsgService.add({
            ...message,
            life: this.getLifetime(message.severity),
        });
    }

    private getLifetime(severity: string | undefined) {
        switch (severity) {
            case 'error':
                return 10000;
            default:
                return 2000;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addBanner(message: Message, callback?: any) {
        const id = Date.now().toString();
        this.pngMsgService.add({
            ...message,
            id,
            key: 'globalBanner',
        });
        if (callback) {
            this.onCloseCallbacks[id] = callback;
        }
    }

    public handleError(error: HttpErrorResponse, source: string) {
        // Initialize an empty message object
        console.error(error);
        const errMessage = error.error.Message;
        let message: Message | undefined;
        // Determine the appropriate error message based on the source
        switch (source) {
            case 'LoginScreenComponent':
                if (error.status === 401) {
                    message = ERROR_MESSAGES['WRONG_USERNAME_PASSWORD'];
                }
                break;

            case 'TimesetScreenComponent':
                if (errMessage === 'Timestamp is older than the last event') {
                    message = ERROR_MESSAGES['OLDER_TIMESET'];
                }
                if (errMessage === 'Timestamp is in the future') {
                    message = ERROR_MESSAGES['FUTURE_TIMESTAMP'];
                }
                if (
                    errMessage === 'Timestamp is outside of the allowed range.'
                ) {
                    message = ERROR_MESSAGES['TIMESTAMP_RANGE'];
                }
                if (
                    errMessage === 'New timestamp must be grater than last one'
                ) {
                    message = ERROR_MESSAGES['MUSTBE_GREATER'];
                }
                if (errMessage === 'Invalid state change') {
                    message = ERROR_MESSAGES['INVALID_CHANGE'];
                }
                break;
            case 'TimesetOverviewComponent':
                if (errMessage === 'Timesets intersect') {
                    message = ERROR_MESSAGES['TIMESET_INTERSECT'];
                }
                if (errMessage === 'Event is too short') {
                    message = ERROR_MESSAGES['EVENT_TO_SHORT'];
                }
                break;
            case 'PasswordResetComponent':
                message = ERROR_MESSAGES['WENT_WRONG'];
                break;
            default:
                if (error.status === 400) {
                    message = {
                        severity: 'error',
                        summary: 'Invalide Anfrage',
                        detail: 'Die gesendete Anfrage ist ungültig, bitte versuche es erneut',
                    };
                }
                break;
        }
        if (!message) {
            message = ERROR_MESSAGES['WENT_WRONG'];
            console.error(error);
        }
        this.pngMsgService.add(message);
    }
}
