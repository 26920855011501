<div class="impressum-container">
    <div class="impressum-header">
        <p-button
            label="Zurück"
            icon="pi pi-arrow-circle-left"
            (click)="back()"
        ></p-button>
    </div>
    <div class="impressum-body">
        <h2>Impressum & Datenschutz</h2>

        <h3>Angaben gem. § 5 TMG:</h3>

        <p>DATAGROUP Consulting Services GmbH</p>
        <p>Genfer Allee 2</p>
        <p>55129 Mainz</p>

        <h3>Kontakt:</h3>
        <p>T +49 6131 914-0</p>
        <p>F +49 6131 914-400</p>
        <p>M VL.DG.CS.Hamburg&#64;datagroup.de</p>

        <h2>Datenschutz</h2>
        @if (privacyPolicy) {
            <div [innerText]="privacyPolicy"></div>
        } @else {
            <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="80%" styleClass="mb-2"></p-skeleton>
            <p-skeleton styleClass="mb-2"></p-skeleton>
            <p-skeleton width="90%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="70%" styleClass="mb-2"></p-skeleton>
        }
        <h3>AWS Datenschutzrichtlinien</h3>
        <p>
            Um die Sicherheit und Vertraulichkeit sensibler Daten zu
            gewährleisten, ist es entscheidend, sich auf zuverlässige
            Dienstleister zu stützen. <br />
            Im Rahmen der Timebox werden Services von Amazon Web Services (AWS)
            genutzt. Die Datenschutzbestimmungen von AWS bilden eine
            grundlegende Säule für den Schutz Ihrer Daten.<br />
            Im Folgenden finden Sie den Link zu den ausführlichen
            Datenschutzrichtlinien von AWS, welche die Maßnahmen und Verfahren
            zur Sicherung und Verwaltung der Informationen beschreiben.
            <br /><a
                style="margin-top: 0.2rem; cursor: pointer"
                href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf"
                >AWS Datenschutzrichtlinien
            </a>
        </p>
    </div>
</div>
