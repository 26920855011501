import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {DatePipe, DecimalPipe, NgStyle} from '@angular/common';
import {EventView} from '../../models/views/EventView';
import {TooltipModule} from '@timebox/custom-primeng/tooltip';
import {ButtonModule} from '@timebox/custom-primeng/button';
import {CalendarModule} from '@timebox/custom-primeng/calendar';
import {colors} from 'src/utils/tools';
import {ScrollPanelModule} from '@timebox/custom-primeng/scrollpanel';
import {TimesetEventType} from 'src/app/models/EventType';

@Component({
    selector: 'app-event-view',
    standalone: true,
    imports: [
        DecimalPipe,
        TooltipModule,
        NgStyle,
        ButtonModule,
        CalendarModule,
        DatePipe,
        ScrollPanelModule,
    ],
    templateUrl: './event-view.component.html',
    styleUrl: './event-view.component.scss',
})
export class EventViewComponent implements OnInit, OnChanges {
    @Input() eventViews: EventView[] = [];

    gt6hEvent = false;
    sumWork = 0;
    sumBreak = 0;
    longestWork = 0;
    gt6ChainStarts: Date[] = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes['eventViews']) {
            this.eventViews = changes['eventViews'].currentValue;
            this.gt6hEvent = false;
            this.sumWork = 0;
            this.sumBreak = 0;
            this.longestWork = 0;
            this.gt6ChainStarts = [];
            const chains: EventView[][] = [];
            const last = this.eventViews.reduce(
                (acc: EventView[], cur: EventView) => {
                    if (cur.type === TimesetEventType.BREAK) {
                        this.sumBreak += cur.duration;
                        chains.push(acc);
                        acc = [];
                    } else {
                        this.sumWork += cur.duration;
                        acc.push(cur);
                    }
                    return acc;
                },
                []
            );
            chains.push(last);
            const filteredChains = chains.filter(
                c => c.reduce((acc, e) => acc + e.duration, 0) > 360
            );
            if (filteredChains.length > 0) {
                const max = Math.max(...filteredChains.map(i => i.length));
                const longest = filteredChains.find(i => i.length === max);
                this.longestWork = longest!.reduce(
                    (acc, e) => acc + e.duration,
                    0
                );
                this.gt6hEvent = this.longestWork > 360;
                this.gt6ChainStarts = this.gt6hEvent
                    ? longest!.map(e => e.start)
                    : [];
            }
        }
    }
    ngOnInit() {}

    formatSum(sumMinutes: number) {
        const h = Math.floor(sumMinutes / 60);
        const m = Math.floor(sumMinutes % 60);
        return `${h}:${m.toString().padStart(2, '0')}`;
    }

    getEventColor(e: EventView) {
        if (this.gt6ChainStarts.includes(e.start)) {
            return colors.warnColor;
        }
        return e.color;
    }

    getWorkColor() {
        return colors.workColor;
    }

    getBreakColor() {
        return colors.breakColor;
    }

    getWarningColor() {
        return colors.warnColor;
    }

    getErrorColor() {
        return colors.errorColor;
    }
}
