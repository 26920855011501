<app-auth [title]="htmlArguments.header" [showLoading]="ongoingRequest">
    <form [formGroup]="challengeForm" (ngSubmit)="setPassword()" #form="ngForm">
        <p-password
            weakLabel="Bitte Richtlinien erfüllen:"
            weakRegex=".+"
            strongLabel="Passwort Richtlinen erfüllt."
            [strongRegex]="passwordStrongRegex"
            mediumRegex=".+"
            mediumLabel="Bitte Richtlinien erfüllen:"
            id="challengePassword"
            type="password"
            formControlName="password"
            placeholder="{{ htmlArguments.input1 }}"
            autocomplete="new-password"
            pTooltip="Geben Sie Ihr neues Passwort ein."
            promptLabel="Neues Passwort"
            [ngStyle]="{
                display: 'flex',
                'text-align': 'left',
                'align-items': 'left'
            }"
        >
            <ng-template pTemplate="footer">
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    @if (!hasUpperCase()) {
                    <li>Großbuchstabe</li>
                    } @if (!hasLowerCase()) {
                    <li>Kleinbuchstabe</li>
                    } @if (!hasNumber()) {
                    <li>Ziffer</li>
                    } @if (!hasSpecialCharacter()) {
                    <li>Sonderzeichen</li>
                    } @if (!hasMinLength()) {
                    <li>
                        Länge:
                        {{
                            8 -
                                (challengeForm.controls.password.value
                                    ?.length ?? 0)
                        }}
                    </li>
                    }
                </ul>
            </ng-template>
        </p-password>
        <input
            pInputText
            id="confirmation"
            type="password"
            formControlName="confirmPassword"
            placeholder="{{ htmlArguments.input2 }}"
            autocomplete="current-password"
            pTooltip="Bestätigen Sie Ihr neues Passwort."
        />
        @if ( challengeForm.controls.password.value !==
        challengeForm.controls.confirmPassword.value ) {
        <span class="error"> Passwörter stimmen nicht überein </span>
        } @if ( !checkPolicy() ) {
        <span class="error"> Passwortrichtlinie nicht erfüllt </span>
        }
    </form>
    <section class="centered-actions">
        <button
            (click)="form.ngSubmit.emit()"
            pButton
            [disabled]="form.invalid || ongoingRequest || !equalPassword()"
            label="Speichern"
            class="p-button"
            pTooltip="Klicken Sie hier, um Ihr neues Passwort zu speichern."
        ></button>
    </section>
</app-auth>
