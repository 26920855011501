import {TimesetEventType} from 'src/app/models/EventType';
import {Timeset} from 'src/app/models/api-models/Timeset';
import {EventView} from 'src/app/models/views/EventView';
import {TimesetView} from 'src/app/models/views/TimesetView';
import {toDate, toTimestamp} from './converter';

interface eventColors {
    workColor: string;
    breakColor: string;
    warnColor: string;
    errorColor: string;
    infoColor: string;
}

export function getEnumFromString<T extends object>(
    enumObject: T,
    value: string
): T[keyof T] {
    const enumKeys = Object.keys(enumObject).filter(key => isNaN(Number(key)));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const enumKey = enumKeys.find(key => (enumObject as any)[key] === value);
    return enumKey
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (enumObject as any)[enumKey]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (enumObject as any)[enumKeys[0]];
}

export const colors: eventColors = {
    workColor: '#90CC8F',
    breakColor: '#8AC6FF',
    warnColor: '#EBC286',
    errorColor: 'var(--orange-500)',
    infoColor: '#8d8d8d',
};

export function getEventTypeColor(t: TimesetEventType): string {
    switch (t) {
        case TimesetEventType.WORK:
            return colors.workColor;
        case TimesetEventType.BREAK:
            return colors.breakColor;
        default:
            return colors.errorColor;
    }
}

export interface BuildEventViewOptions {
    workHours?: number;
    skipValidation?: boolean;
}
export function buildEventView(
    timeset: Timeset,
    options?: BuildEventViewOptions
): EventView[] {
    const result: EventView[] = [];
    const events = timeset.events;

    if (events.length < 1) return [];

    if (!options?.skipValidation && events.some(e => e.end === undefined)) {
        console.warn('Incomplete Timeset was given to Overview');
        return [];
    }

    const estimatedTimesetLength = (options?.workHours ?? 8.5) * 60 * 60;
    const oneS = 100 / estimatedTimesetLength;

    let indentation = 0;

    for (const event of timeset.events) {
        let hasEnd = true;
        if (!event.end) {
            event.end = toTimestamp(new Date());
            hasEnd = false;
        }
        const durationS = event.end! - event.start;
        const weight = durationS * oneS;
        result.push({
            color:
                durationS <= 6 * 60 * 60
                    ? getEventTypeColor(event.type)
                    : colors.warnColor,
            weight,
            duration: Math.trunc(durationS / 60),
            type: event.type,
            start: toDate(event.start),
            end: toDate(event.end!),
            indentation,
            comment: event.comment,
        });
        if (!hasEnd) {
            event.end = undefined;
        }
        indentation += weight;
    }
    return result;
}

export function buildTimesetView(timeset: Timeset): TimesetView {
    const durationInS = timeset.end! - timeset.start;
    const durationInHours = durationInS / 3600;
    const eventViews = buildEventView(timeset, {
        workHours: durationInHours > 8.5 ? durationInHours : 8.5,
    });
    const hasOvertime = eventViews.some(e => e.duration > 6 * 60);
    const result: TimesetView = {
        date: toDate(timeset.start).toLocaleDateString(),
        timesetId: timeset.timesetId,
        events: eventViews,
        icon: hasOvertime ? 'pi-info-circle' : 'pi-check-circle',
        iconColor: hasOvertime ? colors.warnColor : colors.workColor,
    };
    return result;
}

export function roundMinutes(date: Date = new Date(), roundTo: number = 1) {
    const r = 60000 * roundTo;
    return new Date(Math.round(date.getTime() / r) * r);
}
