@if (stepIndex === 0) {
<app-auth [title]="htmlArguments.header" subtitle="Schritt 1 von 2" [showLoading]="ongoingRequest">
    <form
        [formGroup]="requestPasswordForm"
        (ngSubmit)="requestCode()"
        #form="ngForm"
    >
        <input
            pInputText
            formControlName="username"
            tooltipPosition="top"
            placeholder="E-Mail Adresse"
            type="email"
            inputmode="email"
            autocomplete="username"
        />
    </form>
    <section class="centered-actions">
        <button
            (click)="form.ngSubmit.emit()"
            pButton
            [disabled]="requestPasswordForm.invalid"
            label="Anfordern"
            class="p-button"
            pTooltip="Klicken Sie hier, um einen Code zum Zurücksetzen des Passworts anzufordern."
            tooltipPosition="top"
        ></button>
    </section>
</app-auth>

} @if (stepIndex === 1) {
<app-auth [title]="htmlPasswordArguments.header" subtitle="Schritt 2 von 2" [showLoading]="ongoingRequest">
    <form
        [formGroup]="newPasswordForm"
        (ngSubmit)="resetPassword()"
        #form="ngForm"
    >
        <input
            #password
            pInputText
            formControlName="requestCode"
            tooltipPosition="top"
            inputmode="numeric"
            placeholder="Bestätigungscode"
            title="Geben Sie den erhaltenen Code ein."
        />
        <p-password
            #confirmation
            weakLabel="Bitte Richtlinien erfüllen:"
            weakRegex=".+"
            strongLabel="Passwort Richtlinen erfüllt."
            [strongRegex]="passwordStrongRegex"
            mediumRegex=".+"
            mediumLabel="Bitte Richtlinien erfüllen:"
            type="password"
            formControlName="newPassword"
            tooltipPosition="top"
            placeholder="Neues Passwort"
            autocomplete="new-password"
            promptLabel="Neues Passwort"
            [ngStyle]="{
                display: 'flex',
                'text-align': 'left',
                'align-items': 'left'
            }"
        >
            <ng-template pTemplate="footer">
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    @if (!hasUpperCase()) {
                    <li>Großbuchstabe</li>
                    } @if (!hasLowerCase()) {
                    <li>Kleinbuchstabe</li>
                    } @if (!hasNumber()) {
                    <li>Ziffer</li>
                    } @if (!hasSpecialCharacter()) {
                    <li>Sonderzeichen</li>
                    } @if (!hasMinLength()) {
                    <li>
                        Länge:
                        {{
                            8 -
                                (newPasswordForm.controls.newPassword.value
                                    ?.length ?? 0)
                        }}
                    </li>
                    }
                </ul>
            </ng-template>
        </p-password>
    </form>
    <section class="centered-actions">
        <button
            (click)="form.ngSubmit.emit()"
            pButton
            [disabled]="newPasswordForm.invalid || ongoingRequest == true"
            label="Speichern"
            class="p-button"
            pTooltip="Klicken Sie hier, um Ihr neues Passwort zu speichern."
            tooltipPosition="top"
        ></button>
    </section>
</app-auth>
}
