import {Component, ElementRef, Input, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MenuItem} from '@timebox/custom-primeng/api';
import {AuthService} from 'src/app/services/auth.service';
import {MsgService} from 'src/app/services/msg-service.service';
import {SUCCESS_MESSAGES} from 'src/app/services/toast-messages';
import {environment} from 'src/environments/environment';
import {Stage} from 'src/utils/types';
@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css', '../auth.styles.css'],
    providers: [],
    encapsulation: ViewEncapsulation.None,
})
export class PasswordResetComponent {
    constructor(
        private messageService: MsgService,
        private router: Router,
        private authService: AuthService,
        private el: ElementRef
    ) {}
    @Input() username: string = '';
    steps: MenuItem[] = [];
    stepIndex = 0;
    passwordRegex =
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

    ongoingRequest = false;
    ngOnInit(): void {
        this.steps = [
            {label: 'Nutzernamen angeben'},
            {label: 'Bestätigunscode eingeben'},
        ];
    }

    headerRequest = 'Request new Password!';
    headerSet = 'Set your new Password!';

    htmlArguments = {
        header: 'Neues Passwort anfragen',
        input1: 'Email Addresse',
        input2: 'Passwort',
    };

    htmlPasswordArguments = {
        header: 'Neues Passwort setzen',
        input1: 'Gesendeter Code',
        input2: 'Neues Passwort',
    };

    passwordStrongRegex =
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

    activeContainer = (<HTMLElement>this.el.nativeElement).querySelector(
        'content-container1'
    );

    requestPasswordForm = new FormGroup({
        username: new FormControl(this.username, {
            validators: [Validators.required, Validators.email],
        }),
    });
    //Validates that the password has at least one lowercase letter, one uppercase letter, one number, one special character and a minimum length of 8 characters.
    hasLowerCase(): boolean {
        const controlValue =
            this.newPasswordForm.get('newPassword')?.value ?? '';
        const regex = /[a-z]/;
        return regex.test(controlValue);
    }

    hasUpperCase(): boolean {
        const controlValue =
            this.newPasswordForm.get('newPassword')?.value ?? '';
        const regex = /[A-Z]/;
        return regex.test(controlValue);
    }

    hasNumber(): boolean {
        const controlValue =
            this.newPasswordForm.get('newPassword')?.value ?? '';
        const regex = /\d/;
        return regex.test(controlValue);
    }

    hasMinLength(): boolean {
        const controlValue =
            this.newPasswordForm.get('newPassword')?.value ?? '';
        return controlValue.length >= 8;
    }
    hasSpecialCharacter(): boolean {
        const controlValue =
            this.newPasswordForm.get('newPassword')?.value ?? '';
        const regex = /[!@#$%^&*(),.?":{}|<>]/;
        return regex.test(controlValue);
    }

    newPasswordForm = new FormGroup({
        requestCode: new FormControl('', {validators: Validators.required}),
        newPassword: new FormControl('', {
            validators: [
                Validators.required,
                Validators.pattern(this.passwordRegex),
            ],
        }),
    });

    isSandbox() {
        return environment.stage === Stage.sandbox;
    }

    requestCode() {
        this.ongoingRequest = true;
        this.authService
            .resetPassword(
                this.requestPasswordForm.controls.username.value ?? ''
            )
            .then(() => {
                this.ongoingRequest = false;
                this.messageService.add(SUCCESS_MESSAGES['MAIL_SEND']);
                this.stepIndex = 1;
            });
    }

    resetPassword() {
        this.ongoingRequest = true;
        this.authService
            .confirmResetPassword(
                this.requestPasswordForm.controls.username.value ?? '',
                this.newPasswordForm.controls.newPassword.value ?? '',
                this.newPasswordForm.controls.requestCode.value ?? ''
            )
            .then(() => {
                this.ongoingRequest = false;
                this.router.navigateByUrl('/');
            })
            .catch(e => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Fehler beim Ändern des Passworts',
                    detail: (e as Error).message, // auslagern
                });
                this.newPasswordForm.reset();
                this.ongoingRequest = false;
            });
    }
}
