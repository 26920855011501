import {Component} from '@angular/core';
import {MenuItem} from '@timebox/custom-primeng/api/menuitem';
import {AuthService} from 'src/app/services/auth.service';
import {ThemesService} from 'src/app/services/themes.service';

@Component({
    selector: 'app-headerbar',
    templateUrl: './headerbar.component.html',
    styleUrls: ['./headerbar.component.css'],
})
export class HeaderbarComponent {
    title = 'Timebox';
    showMenu = false;
    customDesign = false;
    menuItems: MenuItem[] = [];
    defaultHeaderUrl = 'assets/images/header.jpg';
    headerUrl = this.defaultHeaderUrl;
    defaultLogoUrl = 'assets/images/default_logo.png';
    logoUrl = this.defaultLogoUrl;
    transparentMenuGradient =
        'linear-gradient(45deg, rgba(50, 50, 50, 10%), var(--surface-a) 80%)';
    solidMenuGradient = 'linear-gradient(45deg, var(--surface-a) 80%)';

    constructor(
        private auth: AuthService,
        themeService: ThemesService
    ) {
        this.updateNavBar();
        themeService.designChanged.subscribe({
            next: design => {
                if (design) {
                    this.customDesign = true;
                    this.headerUrl = '';
                    document.documentElement.style.setProperty(
                        '--header-gradient',
                        this.solidMenuGradient
                    );
                    this.logoUrl = design.logo;
                } else {
                    this.customDesign = false;
                    this.headerUrl = this.defaultHeaderUrl;
                    document.documentElement.style.setProperty(
                        '--header-gradient',
                        this.transparentMenuGradient
                    );
                    this.logoUrl = this.defaultLogoUrl;
                }
            },
        });
    }
    updateNavBar() {
        this.menuItems = [
            {
                label: 'Heute',
                icon: 'pi pi-fw pi-clock',
                routerLink: 'today',
            },
            {
                label: 'Meine Zeiten',
                icon: 'pi pi-fw pi-list',
                routerLink: 'times',
            },
            {
                separator: true,
            },
            {
                label: 'Logout',
                icon: 'pi pi-sign-out',
                // routerLink: 'login',
                command: () => {
                    this.auth.signOut();
                },
            },
        ];
    }
}
